import { getPatientInterventions, getPatientReport } from 'api/dashboard.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { Alert, Button, CustomTable, ToastMessage } from 'components/index.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { DefaultTimezone, EventCategoryIcon, formatDateHeader } from 'constants/dashboard.js';
import { TaskStatus } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import Timer from 'icons/Dashboard/Timer.jsx';
import { formatTimeDurationWithSeconds, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const InterventionsWrapper = styled.div`
	margin-top: var(--spacing-sl);
	position: relative;

	> button {
		position: absolute;
		right: var(--spacing-sl);
		top: -70px;
		background: transparent;
		border: 1px solid var(--blue-2);
		padding: var(--spacing-l);
		display: flex;
		align-items: center;
		height: 40px;
		color: var(--blue-2);
		margin-left: auto;
		span {
			color: var(--blue-2);
			margin-left: var(--spacing-s);
			font-weight: 500;
		}
	}
`;

const Interventions = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [taskId, setTaskId] = useState(null);
	const [reportTaskStatus, setReportTaskStatus] = useState(null);
	const [interventions, setInterventions] = useState([]);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, totalCount: 0 });
	const [timezone, setTimezone] = useState(DefaultTimezone);
	const socket = useContext(SocketContext);
	const location = useLocation();
	const intl = useIntl();

	const downloadPatientReport = async () => {
		const { patient, healthSystemId } = location.state;

		const response = await getPatientReport({
			healthSystemId,
			patientId: patient.patientId,
			from: Math.floor(moment(patient.admissionDate).utc(true).valueOf() / 1000),
			to: Math.floor(
				(patient.dischargeDate ? moment(patient.dischargeDate).utc(true).valueOf() : moment().utc().valueOf()) / 1000
			),
			timezone: timezone.zone,
		});

		if (response.error) {
			setError(response.error.message);
			return;
		}
		setTaskId(response.taskId);
	};

	useEffect(() => {
		const fetchPatientInterventions = async () => {
			const { patient, healthSystemId } = location.state;
			setIsLoading(true);
			const params = {
				healthSystemId,
				patientId: patient.patientId,
				from: Math.floor(moment(patient.admissionDate).utc(true).valueOf() / 1000),
				to: Math.floor(
					(patient.dischargeDate ? moment(patient.dischargeDate).utc(true).valueOf() : moment().utc().valueOf()) / 1000
				),
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
			};
			const response = await getPatientInterventions(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setInterventions(prevState => [...prevState, ...response.interventions]);
				setPagination(prevState => ({ ...prevState, totalCount: response.pagination.total }));
			}
			setIsLoading(false);
		};
		fetchPatientInterventions();
		setTimezone(location.state.filters.selectedTimezone);
	}, [location, pagination.pageIndex, pagination.pageSize]);

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (taskId === data.taskId && [TaskStatus.RUNNING, TaskStatus.COMPLETED].includes(data.taskStatusId)) {
				setReportTaskStatus(data.taskStatusId);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket, taskId]);

	const displayPatientInterventions = () =>
		interventions.map(intervention => ({
			type: intervention.eventCategoryName ? (
				<div className='flex flex-align-center'>
					{EventCategoryIcon[intervention.eventCategoryValue]}
					<span className='left-margin-l'>{intervention.eventCategoryName}</span>
				</div>
			) : (
				'N/A'
			),
			event: intervention.event,
			description: intervention?.description || '',
			date: utcToTimezone(intervention.date?.replace('Z', ''), timezone.zone),
			duration: !Number.isNaN(intervention.duration)
				? formatTimeDurationWithSeconds({ seconds: intervention.duration, joinWith: ', ', showDays: false, intl })
				: '',
			intervenedBy: intervention.providerName ? (
				<div className='flex flex-align-center'>
					<ProfilePicture
						fullName={intervention.providerName}
						profilePicture={intervention.providerProfilePicture ?? null}
						className='provider-intervening-img-wrapper'
					/>
					<p className='margin-s no-padding'>{intervention.providerName}</p>
				</div>
			) : (
				'N/A'
			),
		}));

	return (
		<InterventionsWrapper>
			<ToastMessage
				key={reportTaskStatus}
				className='dashboard-report'
				onClose={() => setReportTaskStatus(null)}
				showToast={reportTaskStatus}
				timer={10000}>
				<span>
					{reportTaskStatus === TaskStatus.COMPLETED && <Download color={LightTheme.colors.greenTwo} />}
					{reportTaskStatus === TaskStatus.RUNNING && <Timer color={LightTheme.colors.greenTwo} />}
				</span>
				<div>
					{reportTaskStatus === TaskStatus.COMPLETED && (
						<>
							<span>{translate('reportSentSuccessfully')}</span>
							<p>{translate('toAccessCheckEmail')}</p>
						</>
					)}
					{reportTaskStatus === TaskStatus.RUNNING && (
						<>
							<span>{translate('reportIsUnderway')}</span>
							<p>{translate('reportUnderwayDescription')}</p>
							<p>{translate('thankYouForPatience')}</p>
						</>
					)}
				</div>
			</ToastMessage>
			<Button svgIcon={<Download />} text={translate('downloadPatientReport')} onClick={downloadPatientReport} />
			<div className='patients-table-wrapper'>
				<CustomTable
					headers={[
						{ title: translate('type'), id: 'type' },
						{ title: translate('event'), id: 'event' },
						{ title: translate('description'), id: 'description', columnWidth: '20%' },
						{ title: formatDateHeader(intl, 'date', timezone.zone), id: 'date' },
						{ title: translate('duration'), id: 'duration' },
						{ title: translate('intervenedBy'), id: 'intervenedBy' },
					]}
					rows={displayPatientInterventions()}
					isLoading={isLoading}
					setPagination={setPagination}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</InterventionsWrapper>
	);
};

export default Interventions;
