import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { Alert, Modal } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { addConfig, updateConfig } from 'api/eManager.js';
import { getCompanyId, getUserId, getUserInfo } from 'infrastructure/auth.js';
import { ECareConfigurationTypes } from 'constants/e-care-manager-types.js';
import EcareManagerAddEditBaseUrl from 'containers/Configurations/EcareManagerAddEditBaseUrl.jsx';
import HillRoomAddEdit from 'containers/Integrations/HillRoomAddEdit.jsx';
import EcareApiKey from 'containers/Configurations/EcareApiKey.jsx';
import EcareManagerEpicCerner from 'containers/Configurations/EcareManagerEpicCerner.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { configurationTypeIds, groupTypes } from 'constants/integrationEnums.js';
import PatientInfotainment from 'containers/Integrations/PatientInfotainment.jsx';
import { object, string, ValidationError } from 'yup';
import TranslationServicesModal from 'containers/Configurations/TranslationServicesModal.jsx';

const EpicAddEdit = ({ itemToEdit, hasApiConfig, groupId, setIsRefetch, setIsAddOpen }) => {
	const intl = useIntl();
	const [selectedConfig, setSelectedConfig] = useState({ value: null, label: null, groupId: null });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [fieldErrors, setFieldErrors] = useState(null);
	const [configParams, setConfigParams] = useState({
		nodeId: null,
		parentNodeId: null,
		nodeName: null,
		nodeLevel: null,
		configJson: '',
		configurationTypeId: null,
		validations: {},
		createdBy: '',
	});
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const shouldShowConfigType = settingTypeId =>
		getConfigurationValue(companyConfigurations[settingTypeId]) || itemToEdit?.configurationTypeId;

	const showApiKey = () => {
		if (itemToEdit) {
			return true;
		}
		if (!hasApiConfig) {
			return true;
		}
		return false;
	};

	const configTypes = [
		showApiKey() && {
			value: configurationTypeIds.ECARE_API_KEY,
			label: intl.formatMessage({ id: 'eCareApiKey' }),
			groupId: groupTypes.ECM.id,
		},
		{
			value: configurationTypeIds.ECARE_BASE_URL,
			label: intl.formatMessage({ id: 'eCareBaseUrl' }),
			groupId: groupTypes.ECM.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.EPIC) && {
			value: configurationTypeIds.EPIC,
			label: 'Epic',
			groupId: groupTypes.SMART_FHIR.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.CERNER) && {
			value: configurationTypeIds.CERNER,
			label: 'Cerner',
			groupId: groupTypes.SMART_FHIR.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.HILL_ROM) && {
			value: configurationTypeIds.HILL_ROM,
			label: 'Hill Room',
			groupId: groupTypes.NURSE_CALLING.id,
		},
		{
			value: configurationTypeIds.MOVIES,
			label: intl.formatMessage({ id: 'movies' }),
			groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		},
		{
			value: configurationTypeIds.LIVE_TV_CHANNELS,
			label: intl.formatMessage({ id: 'liveTvChannels' }),
			groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		},
		{
			value: configurationTypeIds.MUSIC,
			label: intl.formatMessage({ id: 'music' }),
			groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.GLOBO) && {
			value: configurationTypeIds.GLOBO,
			label: 'Globo',
			groupId: groupTypes.TRANSLATION_SERVICES.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.AMN) && {
			value: configurationTypeIds.AMN,
			label: 'AMN',
			groupId: groupTypes.TRANSLATION_SERVICES.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.LANGUAGE_LINE) && {
			value: configurationTypeIds.LANGUAGE_LINE,
			label: 'Language Line',
			groupId: groupTypes.TRANSLATION_SERVICES.id,
		},
	].filter(item => Boolean(item) && item.groupId === groupId);

	useEffect(() => {
		const fetchConfigTypes = async () => {
			if (itemToEdit) {
				const foundItem = configTypes.find(item => item.value === itemToEdit.configurationTypeId);
				setSelectedConfig(foundItem);
			}
		};
		fetchConfigTypes();
	}, []);

	const handleYupErrors = error => {
		if (error instanceof ValidationError) {
			const fieldErrors = error.inner.reduce((acc, err) => {
				if (err.path) {
					acc[err.path] = err.message;
				}
				return acc;
			}, {});
			setFieldErrors(fieldErrors);
		}
		return { error: true };
	};

	const getSmartOnFihrValidation = () =>
		object({
			oauthScopes: string().required(intl.formatMessage({ id: 'requiredField' })),
			returnUrl: string().required(intl.formatMessage({ id: 'requiredField' })),
			issuer: string().required(intl.formatMessage({ id: 'requiredField' })),
			clientSecret: string().required(intl.formatMessage({ id: 'requiredField' })),
			clientId: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getHillRomValidation = () =>
		object({
			host: string().required(intl.formatMessage({ id: 'requiredField' })),
			port: string().required(intl.formatMessage({ id: 'requiredField' })),
			path: string().required(intl.formatMessage({ id: 'requiredField' })),
			secretKey: string().required(intl.formatMessage({ id: 'requiredField' })),
			partnerName: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getGloboValidation = () =>
		object({
			authToken: string().required(intl.formatMessage({ id: 'requiredField' })),
			sharedToken: string().required(intl.formatMessage({ id: 'requiredField' })),
			authSecret: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getLanguageLineValidation = () =>
		object({
			authCode: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getAmnValidation = () =>
		object({
			sipEndpoint: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getReidValidation = () =>
		object({
			sipEndpoint: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getEcareApiKeyValidation = () =>
		object({
			apiKey: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getEcareBaseUrlValidation = () =>
		object({
			baseUrl: string().required(intl.formatMessage({ id: 'requiredField' })),
		});

	const getUserSchema = () => {
		let userSchema = {};
		switch (selectedConfig?.value) {
			case configurationTypeIds.ECARE_API_KEY: {
				userSchema = getEcareApiKeyValidation();
				break;
			}
			case configurationTypeIds.ECARE_BASE_URL: {
				userSchema = getEcareBaseUrlValidation();
				break;
			}
			case configurationTypeIds.EPIC:
			case configurationTypeIds.CERNER: {
				userSchema = getSmartOnFihrValidation();
				break;
			}
			case configurationTypeIds.HILL_ROM: {
				userSchema = getHillRomValidation();
				break;
			}
			case configurationTypeIds.GLOBO: {
				userSchema = getGloboValidation();
				break;
			}
			case configurationTypeIds.REID: {
				userSchema = getReidValidation();
				break;
			}
			case configurationTypeIds.LANGUAGE_LINE: {
				userSchema = getLanguageLineValidation();
				break;
			}
			case configurationTypeIds.AMN: {
				userSchema = getAmnValidation();
				break;
			}
			default: {
				userSchema = {};
				break;
			}
		}
		return userSchema;
	};

	const validate = async values => {
		const userSchema = getUserSchema();
		if (Object.keys(userSchema).length > 0) {
			try {
				const isValid = await userSchema?.validate(values, { abortEarly: false });
				return { error: !isValid };
			} catch (error) {
				return handleYupErrors(error);
			}
		}
		return { error: false };
	};

	const onSubmit = async () => {
		setFieldErrors(null);
		const parsedConfigJson = JSON.parse(configParams.configJson);
		parsedConfigJson.createdBy = getUserInfo().email;
		const { error } = await validate(parsedConfigJson);
		if (error) {
			return;
		}
		setIsLoading(true);
		const paramsToSend = {
			tenantId: getCompanyId(),
			configurationTypeId: configParams.configurationTypeId,
			nodeId: configParams.nodeId,
			parentNodeId: configParams.parentNodeId,
			nodeName: configParams.nodeName,
			nodeLevel: configParams.nodeLevel,
			configJson: JSON.stringify(parsedConfigJson),
			userId: getUserId(),
		};
		let response;
		if (itemToEdit) {
			paramsToSend.id = itemToEdit.id;
			response = await updateConfig(paramsToSend);
		} else {
			response = await addConfig(paramsToSend);
		}
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setIsLoading(false);
		setIsRefetch({ id: response.result.id });
		setIsAddOpen(false);
	};

	const isSubmitDisabled = () => configParams.validations && Object.keys(configParams.validations).length > 0;

	return (
		<Modal
			modalSelector='baseUrlKeyModal'
			position='right'
			onModalSubmit={onSubmit}
			onModalClose={() => setIsAddOpen(prevState => !prevState)}
			shouldSubmitOnEnter={false}
			display={true}
			isSubmitDisabled={isSubmitDisabled()}
			isLoading={isLoading}>
			<form>
				<h3>{translate('addConfiguration')}</h3>
				<div className='input'>
					<p className='label'>{translate('selectConfiguration')}</p>
					<p className='font-14'>{translate('selectConfigurationDesc')}</p>
					<Select
						isDisabled={itemToEdit}
						value={selectedConfig}
						placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
						classNamePrefix='react-select'
						options={configTypes}
						onChange={setSelectedConfig}
					/>
					{!configParams.configurationTypeId && <small>{translate('configurationTypeRequired')}</small>}
					<br />
					<br />
					{selectedConfig?.value === configurationTypeIds.ECARE_API_KEY && (
						<EcareApiKey itemToEdit={itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.value === configurationTypeIds.ECARE_BASE_URL && (
						<EcareManagerAddEditBaseUrl itemToEdit={itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.groupId === groupTypes.SMART_FHIR.id && (
						<EcareManagerEpicCerner
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							type={selectedConfig.value}
							fieldErrors={fieldErrors}
						/>
					)}
					{selectedConfig?.groupId === groupTypes.NURSE_CALLING.id && (
						<HillRoomAddEdit itemToEdit={itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.groupId === ECareConfigurationTypes.PATIENT_INFOTAINMENT && (
						<PatientInfotainment
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
						/>
					)}
					{selectedConfig?.groupId === groupTypes.TRANSLATION_SERVICES.id && (
						<TranslationServicesModal
							itemToEdit={itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
							fieldErrors={fieldErrors}
						/>
					)}
				</div>
			</form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default EpicAddEdit;
