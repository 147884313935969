import { DeviceModes, TeamTypes } from "constants/enums.js";
import { gatewayApi } from "constants/global-variables.js";
import { getCompanyId } from "infrastructure/auth.js";

export const getAssignedDevices = async (healthSystemId, pageSize, pageIndex, search = '') => {
    try {
        const { data } = await gatewayApi.get(`/v1/organizations/${getCompanyId()}/teams/${healthSystemId}/team-types/${TeamTypes.HEALTH_SYSTEM}/devices/nurse-stations`,
            {
                params: {
                    'page-size': pageSize,
                    'current-page': pageIndex,
                    ...(search && { search }),
                }
            }
        );
        return data;
    } catch (error) {
        return { error };
    }
};

export const getUnassociatedDevices = async () => {
    try {
        const { data } = await gatewayApi.get(`/v1/organizations/${getCompanyId()}/device-modes/${DeviceModes.NURSE_STATION}/devices/unassociated`);
        return data;
    } catch (error) {
        return { error };
    }
};

export const assignDevice = async (healthSystemId, deviceId, payload) => {
    try {
        let response = await gatewayApi.put(
            `/v1/organizations/${getCompanyId()}/teams/${healthSystemId}/team-types/${TeamTypes.HEALTH_SYSTEM}/devices/${deviceId}`,
            payload
        );
        return response;
    } catch (error) {
        return error;
    }
}