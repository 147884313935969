import React, { useMemo } from 'react';
import classNames from 'classnames';
import { RiskLevels } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';

const RiskLevelFilters = ({ totalCount, scores, riskType, filterByRisk, filterDefault }) => {
	const riskLevels = useMemo(
		() => [
			{ label: 'numberOfPatients', value: null, score: totalCount, onClick: filterDefault },
			{ label: 'highRisk', value: RiskLevels.HIGH, score: scores.high },
			{ label: 'moderateRisk', value: RiskLevels.MODERATE, score: scores.moderate },
			{ label: 'lowRisk', value: RiskLevels.LOW, score: scores.low },
		],
		[totalCount, scores, filterDefault]
	);

	return (
		<div className='flex patient-risk-grid'>
			{riskLevels.map(({ label, value, score, onClick }) => (
				<div
					key={label}
					onClick={onClick || (() => filterByRisk(value))}
					className={classNames('cursor-pointer', { active: riskType === value })}>
					<p>{translate(label)}</p>
					<h1>{score}</h1>
				</div>
			))}
		</div>
	);
};

export default React.memo(RiskLevelFilters);
