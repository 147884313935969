import {
	AiAlertActivityType,
	AiAlertId,
	AlertCategory,
	ManualAlertActivityTypes,
	ManualAlertTypes,
	MeasurementActivityTypes,
	MeasurementAlertId,
	MeasurementTypes,
	PatientAiSetting,
} from 'constants/enums.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import translate from 'i18n-translations/translate.jsx';
import BodyMeasurementsIcon from 'icons/HealthData/BodyMeasurementsIcon.jsx';
import GlucoseIcon from 'icons/HealthData/GlucoseIcon.jsx';
import HeartIcon from 'icons/HealthData/HeartIcon.jsx';
import OtherDataIcon from 'icons/HealthData/OtherDataIcon.jsx';
import RespiratoryIcon from 'icons/HealthData/RespiratoryIcon.jsx';
import SleepIcon from 'icons/HealthData/SleepIcon.jsx';
import BedRails from 'icons/Monitoring/BedRails.jsx';
import Conversation from 'icons/Monitoring/Conversation.jsx';
import Doctor from 'icons/Monitoring/Doctor.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import HandWashing from 'icons/Monitoring/HandWashing.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import PatientGettingOutOfChairIcon from 'icons/Monitoring/PatientGettingOutOfChairIcon.js';
import PatientPosition from 'icons/Monitoring/PatientPosition.jsx';
import { BodyPosition, RailOptions, SensitivityTypes, createMessage, findLabelByValue, parseInputString } from './aiHelper.js';
import { AlertTypeToSettingTypeMap } from 'constants/alerts.js';
import {
	dangerSound,
	extremelyHighPressure,
	extremelyHighTemperature,
	extremelyLowOxygen,
	extremelyLowPressure,
	fallAttentionSound,
	highBloodGlucose,
	highBloodPressure,
	highHeartRate,
	highTemperature,
	ivBagSound,
	lowHeartRate,
	lowOxygen,
	lowTemperature,
	obese,
	overWeight,
	personPositionSound,
	railSound,
	underWeight,
} from 'constants/soundAlerts.js';

const getMeasurementAlertIcon = alertType => {
	switch (alertType) {
		case MeasurementTypes.BLOOD_PRESSURE:
		case MeasurementTypes.HEART_RATE:
			return <HeartIcon />;
		case MeasurementTypes.OXYGEN:
			return <RespiratoryIcon />;
		case MeasurementTypes.DIABETES:
			return <OtherDataIcon />;
		case MeasurementTypes.TEMPERATURE:
		case MeasurementTypes.WEIGHT:
			return <BodyMeasurementsIcon />;
		case MeasurementTypes.SLEEP:
			return <SleepIcon />;
		case MeasurementTypes.BLOOD_GLUCOSE:
			return <GlucoseIcon />;
		default:
			return <HeartIcon />;
	}
};

const getManualAlertIcon = alertType => {
	switch (alertType) {
		case ManualAlertTypes.FALL_DETECTED:
			return <RespiratoryIcon />;
		case ManualAlertTypes.CUSTOM:
			return <OtherDataIcon />;
		case ManualAlertTypes.RISK_OF_FALL:
			return <BodyMeasurementsIcon />;
		case ManualAlertTypes.PATIENT_NEEDS_HELP:
			return <SleepIcon />;
		case ManualAlertTypes.PATIENT_REQUESTED_HELP:
			return <GlucoseIcon />;
		default:
			return <HeartIcon />;
	}
};

const getAiAlertIcon = (alertType, darkMode) => {
	const color = darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive;

	switch (alertType) {
		case AiAlertId.RAILS:
			return <BedRails color={color} />;
		case AiAlertId.PATIENT_SITTING:
		case AiAlertId.PATIENT_STANDING:
		case AiAlertId.PATIENT_FACE_DOWN:
		case AiAlertId.PATIENT_LYING_SIDE:
		case AiAlertId.PATIENT_LYING_FRONT:
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
			return <PatientPosition color={color} />;
		case AiAlertId.FALL_DETECTION:
			return <FallDetection color={color} />;
		case AiAlertId.HANDS_DISINFECTED:
			return <HandWashing color={color} />;
		case AiAlertId.LOW_IV_BAG:
			return <IvBag color={color} />;
		case AiAlertId.PATIENT_GETTING_OUT_OF_CHAIR:
			return <PatientGettingOutOfChairIcon color={color} />;
		default:
			return <PatientPosition color={color} />;
	}
};

const getImageComponent = (type, darkMode) => {
	const color = darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive;
	switch (type) {
		case AiAlertActivityType.AI_ALERT_SENT:
		case AiAlertActivityType.AI_ALERT_RECEIVED:
		case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
		case AiAlertActivityType.ACKNOWLEDGED:
		case AiAlertActivityType.AI_FAKE_ALERT:
		case AiAlertActivityType.AI_FORWARD_TO_NURSES:
			return <Doctor color={color} />;
		case AiAlertActivityType.COMMENTED:
		case MeasurementActivityTypes.COMMENTED:
			return <Conversation color={color} />;
		default:
			return <Doctor color={color} />;
	}
};

const getAlertImage = (item, darkMode, typeProperty, getIconFunction) => {
	if (item[typeProperty]) {
		if (item.properties && JSON.parse(item.properties)?.Id) {
			return getIconFunction(JSON.parse(item.properties)?.Name, darkMode);
		}
		return getImageComponent(item[typeProperty].id, darkMode);
	}
	return null;
};

export const getImageUrl = (item, darkMode) => {
	return (
		getAlertImage(item, darkMode, 'aiAlertActivityType', getAiAlertIcon) ||
		getAlertImage(item, darkMode, 'measurementAlertActivityType', getMeasurementAlertIcon) ||
		getAlertImage(item, darkMode, 'manualAlertActivityType', getManualAlertIcon)
	);
};

const getDescriptionForType = (id, sentAlert, companySettings) => {
	switch (id) {
		case AiAlertActivityType.AI_ALERT_SENT:
			return translate(sentAlert);
		case AiAlertActivityType.AI_ALERT_RECEIVED:
			return translate('receivedAlert');
		case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
			return (
				<>
					{translate('wasAddedAs')}
					<span className='hello-device'>{translate('hospitalCareTaker')}</span>
				</>
			);
		case AiAlertActivityType.COMMENTED:
			return <>{translate('addedComment')}:</>;
		case AiAlertActivityType.ACKNOWLEDGED:
			return (
				<>
					{translate('markedThe')}
					<span className='completed-alert'>{translate('alertAcknowledged')}</span>
				</>
			);
		case AiAlertActivityType.AI_FAKE_ALERT:
			return (
				<>
					{translate('markedThe')}
					<span className='false-alert'>{translate('alertAsFalse')}</span>
				</>
			);
		case AiAlertActivityType.AI_FORWARD_TO_NURSES:
			return (
				<>
					{translate('has')}
					<span className='hello-device'>
						{translate('forwardedAlertToSomethings', { value: companySettings.nurseDisplayName })}
					</span>
				</>
			);
		case ManualAlertActivityTypes.SAVED_ACTIVITY:
			return (
				<>
					{translate('has')}
					<span className='hello-device'>{translate('savedCareEvent')}</span>
					<br />
				</>
			);
		case ManualAlertActivityTypes.STOPPED_STAT_ALARM:
			return (
				<>
					{translate('has')}
					<span className='hello-device'>{translate('hasStoppedStatAlarm')}</span>
				</>
			);
		default:
			return '';
	}
};

export const getAlertDescription = (item, companySettings) => {
	if (item.aiAlertActivityType) {
		return getDescriptionForType(item.aiAlertActivityType?.id, 'isAtRisk', companySettings);
	}
	if (item.measurementAlertActivityType) {
		return getDescriptionForType(item.measurementAlertActivityType?.id, 'measured', companySettings);
	}
	if (item.manualAlertActivityType) {
		return getDescriptionForType(item.manualAlertActivityType?.id, 'raisedAnAlert', companySettings);
	}
	return null;
};

export const getItemProperties = item => {
	if (!item.properties) {
		return null;
	}
	return JSON.parse(item.properties);
};

export const getItemComment = (item, intl) => {
	if (!item) {
		return '';
	}
	const result = item.Comment || (item.Name && intl.formatMessage({ id: item.Name }));
	if (!result) {
		return '';
	}
	return result;
};

export const getTranslatedComment = (comment, intl) => {
	return comment && typeof comment === 'string' ? intl.formatMessage({ id: comment }) : comment;
};

export const typesWithConfigsDetails = intl => [
	{ id: AiAlertId.RAILS, label: intl.formatMessage({ id: 'rails' }) },
	{ id: AiAlertId.PATIENT_GETTING_OUT_OF_BED, label: intl.formatMessage({ id: 'getOutOfBed' }) },
	{ id: AiAlertId.PATIENT_INACTIVE, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_LYING_FRONT, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_LYING_LEFT_SIDE, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_LYING_RIGHT_SIDE, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_FACE_DOWN, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_SITTING, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_STANDING, label: intl.formatMessage({ id: 'pressureInjury' }) },
	{ id: AiAlertId.PATIENT_INACTIVE_INFORMATION, label: intl.formatMessage({ id: 'pressureInjury' }) },
];

export const formatAlertConfigLog = (label, settings, intl) => {
	const { settingTypeId, pressureInjuryBodyPositions, value } = settings;

	switch (settingTypeId) {
		case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
			return createMessage('gobConfigurationMessage', { value1: label, value2: findLabelByValue(SensitivityTypes(intl), value) });
		case PatientAiSetting.RAILS:
			return createMessage('alertConfigurationMessage', { value1: label, value2: parseInputString(RailOptions(intl), value) });
		case PatientAiSetting.PRESSURE_INJURY:
			return createMessage('alertConfigurationMessage', {
				value1: label,
				value2: parseInputString(BodyPosition(intl), JSON.stringify(pressureInjuryBodyPositions)),
			});
		default:
			return '';
	}
};

export const getDetailsByLastActivity = (activityType, nurseDisplayName) => {
	switch (activityType) {
		case AiAlertActivityType.AI_FAKE_ALERT:
			return { text: translate('falseAlert'), color: 'var(--red-3)', icon: 'close' };
		case AiAlertActivityType.ACKNOWLEDGED:
			return { text: translate('acknowledged'), color: 'var(--green-4)', icon: 'done' };
		case AiAlertActivityType.AI_FORWARD_TO_NURSES:
			return {
				text: translate('forwardedToSomething', { value: nurseDisplayName }),
				color: 'var(--blue-light-5)',
				icon: 'arrow_forward',
			};
		default:
			return { text: '', color: '', icon: '' };
	}
};

export const getAiVoiceMessageByType = type => {
	switch (type) {
		case AiAlertId.SNEEZING:
			return translate('sneezingDetected');
		case AiAlertId.SNORING:
			return translate('snoringDetected');
		case AiAlertId.COUGHING:
			return translate('coughingDetected');
		case AiAlertId.CRYING_BABY:
			return translate('cryingBabyDetected');
		case AiAlertId.SCREAMING:
			return translate('screamingDetected');
		case AiAlertId.HELP:
			return translate('helpDetected');
		case AiAlertId.LOW_PRIORITY_ALARM:
			return translate('lowPriorityAlarmDetected');
		case AiAlertId.MEDIUM_PRIORITY_ALARM:
			return translate('mediumPriorityAlarmDetected');
		case AiAlertId.HIGH_PRIORITY_ALARM:
			return translate('highPriorityAlarmDetected');
		case AiAlertId.ALARM_OFF:
			return translate('alarmOffDetected');
		case AiAlertId.VITAL_SIGN_ALARM:
			return translate('vitalSignDetected');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getAlertMessage = typeId => {
	switch (typeId) {
		case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
			return translate('patientWasDetectedGettingOutOfBed');
		case PatientAiSetting.FALL_DETECTED:
			return translate('patientWasDetectedFalling');
		case PatientAiSetting.RAILS:
			return translate('railWasDetectedDown');
		default:
			return '';
	}
};

export const getPressureInjuryMessageByType = type => {
	switch (type) {
		case AiAlertId.PATIENT_SITTING:
			return translate('patientSitting');
		case AiAlertId.PATIENT_STANDING:
			return translate('patientStanding');
		case AiAlertId.PATIENT_LYING_FRONT:
			return translate('patientLyingFront');
		case AiAlertId.PATIENT_LYING_SIDE:
			return translate('patientLyingSide');
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
			return translate('patientLyingRightSide');
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
			return translate('patientLyingLeftSide');
		case AiAlertId.PATIENT_FACE_DOWN:
			return translate('patientFaceDown');
		case AiAlertId.PATIENT_INACTIVE:
		case AiAlertId.PATIENT_INACTIVE_INFORMATION:
			return translate('patientInactive');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getNotificationOptions = ({ alertTypeId, deviceId, aiSettingList = [] }) => {
	const foundSettingTypeId = AlertTypeToSettingTypeMap?.[alertTypeId];
	const foundAiSettings = aiSettingList.find(item => item.deviceId === deviceId);
	if (!foundSettingTypeId || !foundAiSettings) {
		return [];
	}
	return foundAiSettings.settings.find(item => item.settingTypeId === foundSettingTypeId)?.notificationOptions || [];
};

const getMeasurementAlert = alertTypeId => {
	switch (alertTypeId) {
		case MeasurementAlertId.BLOOD_GLUCOSE_HIGH:
			return {
				voiceOver: highBloodGlucose,
				text: translate('highBloodGlucose'),
			};
		case MeasurementAlertId.HEART_RATE_LOW:
			return {
				voiceOver: lowHeartRate,
				text: translate('lowHeartRateDescription'),
			};
		case MeasurementAlertId.HEART_RATE_HIGH:
			return {
				voiceOver: highHeartRate,
				text: translate('highHeartRateDescription'),
			};
		case MeasurementAlertId.BODY_TEMPERATURE_LOW:
			return {
				voiceOver: lowTemperature,
				text: translate('lowTemperature'),
			};
		case MeasurementAlertId.BODY_TEPERATURE_HIGH:
			return {
				voiceOver: highTemperature,
				text: translate('highTemperature'),
			};
		case MeasurementAlertId.BODY_TEMPERATURE_CRITICAL_HIGH:
			return {
				voiceOver: extremelyHighTemperature,
				text: translate('extremelyHighTemperature'),
			};
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_LOW:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_LOW:
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_CRITICAL_LOW:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_CRITICAL_LOW:
			return {
				voiceOver: extremelyLowPressure,
				text: translate('extremelyLowBloodPressure'),
			};
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_HIGH:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_HIGH:
			return {
				voiceOver: highBloodPressure,
				text: translate('highBloodPressure'),
			};
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_CRITICAL_HIGH:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_CRITICAL_HIGH:
			return {
				voiceOver: extremelyHighPressure,
				text: translate('extremelyHighPressure'),
			};
		case MeasurementAlertId.BMI_LOW:
			return {
				voiceOver: underWeight,
				text: translate('underweight'),
			};
		case MeasurementAlertId.BMI_HIGH:
			return {
				voiceOver: overWeight,
				text: translate('overweight'),
			};
		case MeasurementAlertId.BMI_EXTREMELY_HIGH:
			return {
				voiceOver: obese,
				text: translate('obese'),
			};
		case MeasurementAlertId.OXYGEN_SATURATION_LOW:
			return {
				voiceOver: lowOxygen,
				text: translate('lowOxygen'),
			};
		case MeasurementAlertId.OXYGEN_SATURATION_CRITICAL_LOW:
			return {
				voiceOver: extremelyLowOxygen,
				text: translate('extremelyLowOxygen'),
			};
		default:
			return { voiceOver: null, text: null };
	}
};

const getAiAlert = alertTypeId => {
	switch (alertTypeId) {
		case AiAlertId.FALL_DETECTION:
			return {
				voiceOver: fallAttentionSound,
				text: translate('pleaseCheckPatient'),
				alertCategory: AlertCategory.FALL_DETECTION,
			};
		case AiAlertId.PATIENT_SITTING:
		case AiAlertId.PATIENT_STANDING:
		case AiAlertId.PATIENT_FACE_DOWN:
		case AiAlertId.PATIENT_LYING_FRONT:
		case AiAlertId.PATIENT_LYING_SIDE:
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
		case AiAlertId.PATIENT_INACTIVE:
		case AiAlertId.PATIENT_INACTIVE_INFORMATION:
			return {
				voiceOver: personPositionSound,
				text: getPressureInjuryMessageByType(alertTypeId),
				alertCategory: AlertCategory.PRESSURE_INJURY,
			};
		case AiAlertId.PATIENT_GETTING_OUT_OF_BED:
			return {
				voiceOver: personPositionSound,
				text: translate('patientGettingOutOfBed'),
				alertCategory: AlertCategory.GETTING_OUT_OF_BED,
			};
		case AiAlertId.PATIENT_GETTING_OUT_OF_CHAIR:
			return {
				voiceOver: personPositionSound,
				text: translate('personGettingOutOfChairMessage'),
				alertCategory: AlertCategory.PATIENT_MOBILITY,
			};
		case AiAlertId.RAILS:
			return {
				voiceOver: railSound,
				text: translate('railDownMessage'),
				alertCategory: AlertCategory.RAILS,
			};
		case AiAlertId.PATIENT_MOBILITY:
			return {
				voiceOver: personPositionSound,
				text: translate('personIsDetectedWalking'),
				alertCategory: AlertCategory.PATIENT_MOBILITY,
			};
		case AiAlertId.IV_BAG:
		case AiAlertId.LOW_IV_BAG:
			return {
				voiceOver: ivBagSound,
				text: translate('ivBagCheck'),
				alertCategory: AlertCategory.IV_BAG,
			};
		case AiAlertId.SNEEZING:
		case AiAlertId.SNORING:
		case AiAlertId.COUGHING:
		case AiAlertId.CRYING_BABY:
		case AiAlertId.SCREAMING:
		case AiAlertId.HELP:
		case AiAlertId.LOW_PRIORITY_ALARM:
		case AiAlertId.MEDIUM_PRIORITY_ALARM:
		case AiAlertId.HIGH_PRIORITY_ALARM:
		case AiAlertId.ALARM_OFF:
		case AiAlertId.VITAL_SIGN_ALARM: {
			return {
				voiceOver: personPositionSound,
				text: getAiVoiceMessageByType(alertTypeId),
				alertCategory: AlertCategory.AI_VOICE_ANALYSIS,
			};
		}
		default:
			return {
				voiceOver: dangerSound,
				text: translate('pleaseCheckPatient'),
				alertCategory: null,
			};
	}
};

export const getAlertDetailsById = ({ alertTypeId, isAiAlert = false }) =>
	isAiAlert ? getAiAlert(alertTypeId) : getMeasurementAlert(alertTypeId);
