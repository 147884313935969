import {
	EWSType,
	LevelOfConsciousness,
	RiskLevelValues,
	RiskLevels,
	SupplementOxygen,
	VitalSignsEwsScoreLevels,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { HospitalAtHomeActiveTab } from 'constants/hospital-at-home.js';
import { MedicationStatus, ScheduledSurveyType, SurveyType } from 'constants/rpm.js';

const imageUrl = `${healthCareCdnUrl}vsm/`;

export const getScoreRisk = (score, isRedScore = false) => {
	if (score >= 0 && score <= 4) {
		return RiskLevels.LOW;
	}
	if (isRedScore || (score >= 5 && score <= 6)) {
		return RiskLevels.MODERATE;
	}
	if (score >= 7) {
		return RiskLevels.HIGH;
	}
	return 'N/A';
};

export const getScoreRiskClassName = (score, isRedScore = false) => {
	if (score >= 0 && score <= 4) {
		return 'low-risk-wrapper';
	}
	if (isRedScore || (score >= 5 && score <= 6)) {
		return 'mod-risk-wrapper';
	}
	if (score >= 7) {
		return 'high-risk-wrapper';
	}
	return '';
};

export const getScoreRiskInnerClassName = (score, isRedScore = false) => {
	if (score >= 0 && score <= 4) {
		return 'low-risk';
	}
	if (isRedScore || (score >= 5 && score <= 6)) {
		return 'mod-risk';
	}
	if (score >= 7) {
		return 'high-risk';
	}
	return '';
};

export const getImageBasedOnScore = (score, isRedScore = false, isDarkMode) => {
	let image = `${healthCareCdnUrl}ews-settings${isDarkMode ? '-white' : ''}.svg`;
	if (isRedScore) {
		image = `${imageUrl}score-orange.svg`;
		return image;
	}
	switch (getScoreRisk(score)) {
		case RiskLevels.LOW:
			image = `${imageUrl}score-blue.svg`;
			break;
		case RiskLevels.MODERATE:
			image = `${imageUrl}score-orange.svg`;
			break;
		case RiskLevels.HIGH:
			image = `${imageUrl}score-red.svg`;
			break;
		case 'N/A':
			image = `${healthCareCdnUrl}ews-settings${isDarkMode ? '-white' : ''}.svg`;
			break;
		default:
			break;
	}
	return image;
};

export const getVitalSignsScoreClassName = (score, isInnerDiv) => {
	switch (score) {
		case VitalSignsEwsScoreLevels.LOW:
			return isInnerDiv ? 'low-risk' : 'low-risk-wrapper';
		case VitalSignsEwsScoreLevels.MODERATE:
			return isInnerDiv ? 'mod-risk' : 'mod-risk-wrapper';
		case VitalSignsEwsScoreLevels.HIGH:
			return isInnerDiv ? 'high-risk' : 'high-risk-wrapper';
		default:
			return '';
	}
};

export const getRadioButtonOptions = name =>
	[
		{
			id: 0,
			content: name === EWSType.SUPPLEMENT_OXYGEN ? 'No' : 'A',
			value: name === EWSType.SUPPLEMENT_OXYGEN ? SupplementOxygen.NO : LevelOfConsciousness.A,
		},
		{
			id: 1,
			content: name === EWSType.SUPPLEMENT_OXYGEN ? 'Yes' : 'VPU',
			value: name === EWSType.SUPPLEMENT_OXYGEN ? SupplementOxygen.YES : LevelOfConsciousness.VPU,
		},
	].filter(Boolean);

export const getSurveyType = survey => {
	switch (survey) {
		case SurveyType.GENERIC:
			return 'generic';
		case SurveyType.SAFETY_RANGE:
			return 'safetyRange';
		case SurveyType.SCHEDULED:
			return 'scheduled';
		default:
			return null;
	}
};

export const getScheduledSurveyType = survey => {
	switch (survey) {
		case ScheduledSurveyType.DAILY:
			return 'daily';
		case ScheduledSurveyType.WEEKLY:
			return 'weekly';
		case ScheduledSurveyType.MONTHLY:
			return 'monthly';
		default:
			return null;
	}
};

export const getBatteryImage = batteryPercentage => {
	if (batteryPercentage >= 0 && batteryPercentage <= 20) {
		return 'low-battery';
	}
	if (batteryPercentage > 20 && batteryPercentage < 90) {
		return 'half-battery';
	}
	if (batteryPercentage >= 90 && batteryPercentage <= 100) {
		return 'full-battery';
	}

	return '';
};

export const getBatteryColorBasedOnScore = (score, batteryLevel) => {
	const imageBase = getBatteryImage(batteryLevel);
	switch (score) {
		case RiskLevelValues.NORMAL:
			return `${imageBase}-blue`;
		case RiskLevelValues.MODERATE:
			return `${imageBase}-orange`;
		case RiskLevelValues.HIGH:
			return `${imageBase}-red`;
		default:
			return `${imageBase}`;
	}
};

export const getMedicationIcon = (status, isMedic = false) => {
	switch (status) {
		case MedicationStatus.SKIPPED:
			return isMedic ? 'red' : 'reject';
		case MedicationStatus.TAKEN:
			return isMedic ? 'green' : 'done';
		default:
			return isMedic ? 'gray' : 'default';
	}
};

export const getHospitalAtHomeClassName = index => {
	switch (index) {
		case HospitalAtHomeActiveTab.ALL_PATIENTS:
			return 'all-patients';
		case HospitalAtHomeActiveTab.MY_PATIENTS:
			return 'my-patients';
		case HospitalAtHomeActiveTab.HIGH_RISK:
			return 'high-risk';
		case HospitalAtHomeActiveTab.MODERATE_RISK:
			return 'moderate-risk';
		case HospitalAtHomeActiveTab.LOW_RISK:
			return 'low-risk';
		default:
			return 'all-patients';
	}
};

export const hasOnlyOneScoreOfThree = arr => {
	let scoreOfThreeCount = 0;
	const highScorePerVitalSign = 3;
	const result = arr.every(obj => {
		if (obj.earlyWarningScore === highScorePerVitalSign) {
			scoreOfThreeCount += 1;
		} else if (obj.earlyWarningScore && obj.earlyWarningScore !== 0) {
			return false;
		}

		return true;
	});

	return result && scoreOfThreeCount === 1;
};

export const getFilterBasedOnScoreLength = (list, score) => list?.filter(item => getScoreRisk(item?.score) === score).length;

export const getHospitalAtHomeScoreClassName = (score, isInnerDiv = false) => {
	switch (score) {
		case VitalSignsEwsScoreLevels.LOW:
			return isInnerDiv ? 'low-score' : 'low-score-wrapper';
		case VitalSignsEwsScoreLevels.MODERATE:
			return isInnerDiv ? 'mod-score' : 'mod-score-wrapper';
		case VitalSignsEwsScoreLevels.HIGH:
			return isInnerDiv ? 'high-score' : 'high-score-wrapper';
		default:
			return '';
	}
};

export const scrollToTop = refList => {
	if (!refList.current) {
		return;
	}
	refList.current.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};
