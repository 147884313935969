import React, { useState, useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import {
	Option,
	MultiValueContainer,
	MultiValue,
	MenuList,
	Control,
} from 'components/Common/ReactSelectCustomComponents/index.js';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const CustomDropdown = ({
	onSelect,
	placeholder,
	initialOptions,
	defaultOptions,
	labelName = '',
	showTitleInPlaceholder = false,
	title = '',
	isMulti = true,
	isSearchable = false,
	isDarkModeCompatible = true,
	handleKeyDown,
	isDisabled = false,
}) => {
	const user = useSelector(state => state.user);
	const dropdownOptions = useRef(initialOptions);
	const [selectedOptions, setSelectedOptions] = useState(defaultOptions || []);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const customContentLabel =
		selectedOptions && Array.isArray(selectedOptions) && selectedOptions.map(option => option.label).join(', ');

	const handleSelectOptions = (items, { action }) => {
		setSelectedOptions(items);
		if (action === 'deselect-option' && selectedOptions?.length === 1) {
			setIsDropdownOpen(false);
		}
		if (onSelect) {
			onSelect(items);
		}
		if (!isMulti) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(() => {
		setSelectedOptions(defaultOptions);
	}, [defaultOptions]);

	useEffect(() => {
		dropdownOptions.current = initialOptions;
	}, [initialOptions]);

	let customContent = selectedOptions?.length > 0 ? `${customContentLabel}` : '';

	if (showTitleInPlaceholder) {
		customContent = title;
	}

	const isDarkMode = isDarkModeCompatible ? user.darkMode : false;

	return (
		<>
			<label>{labelName}</label>
			<ReactSelect
				menuIsOpen={isDropdownOpen}
				onMenuOpen={() => setIsDropdownOpen(true)}
				onMenuClose={() => setIsDropdownOpen(false)}
				options={dropdownOptions.current}
				value={selectedOptions}
				placeholder={placeholder}
				onChange={handleSelectOptions}
				isDisabled={isDisabled}
				isClearable={false}
				isSearchable={isSearchable}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				classNamePrefix='react-select'
				className={classNames('custom-dropdown', isMulti ? 'multi-custom-dropdown' : '')}
				onKeyDown={handleKeyDown}
				customContent={searchInput.length > 0 || (isSearchable && isFocused) ? '' : customContent}
				onInputChange={setSearchInput}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				styles={{
					...generateCustomStyles({ darkMode: isDarkMode }),
					menu: base => ({ ...base, width: '100%', color: isDarkMode && DarkTheme.colors.grayFive }),
					menuList: base => ({ ...base, height: 'fit-content', width: '100%' }),
					multiValue: base => ({
						...base,
						display: 'none',
					}),
					control: base => ({
						...base,
						paddingLeft: '10px',
						backgroundColor: isDarkMode && 'var(--dark-theme-gray-2)',
						color: isDarkMode && DarkTheme.colors.grayFive,
					}),
				}}
				isMulti={isMulti}
				components={{
					Option,
					MultiValueContainer,
					MultiValue,
					Control,
					MenuList,
				}}
			/>
		</>
	);
};

export default CustomDropdown;
