import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useIMask } from 'react-imask';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { parsePhoneNumber, isValidPhoneNumber as isValidNumberFromSip } from 'libphonenumber-js';
import { v4 } from 'uuid';
import { Modal, Input, Button, Checkbox, Avatar } from 'calls/components/index.js';
import { countriesData, emailValidationRegEx } from 'calls/constants/index.js';
import { useConference, useConferenceConfigurations, useConferenceParticipants, useLocalParticipant } from 'calls/hooks/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getRoomMembers } from 'api/organization.js';
import { ButtonType, KeyCodes, VisitorType } from 'constants/enums.js';
import { getSipInfo, dialOut, dialOutTranslator } from 'api/sipProvider.js';
import CustomSelect from 'components/Select.jsx';
import { createAssignment, deleteAssignment, getGloboLanguages } from 'api/globo.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { AssignmentStatus } from 'constants/globoEnums.js';
import Alert from 'components/Alert.jsx';
import { getLowestLevelInterperterConfiguration } from 'api/translationServices.js';
import classNames from 'classnames';
import { languages } from 'constants/languageLine.js';
import Select from 'react-select';
import CustomButton from 'components/Button.jsx';
import { findSectorById, isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isRightToLeft: boolean, $isDarkMode: boolean }, never>}
 */
const StyledInviteParticipants = styled.div`
	section {
		background: ${LightTheme.colors.grayZero};
		header {
			border-bottom: 1px solid ${LightTheme.colors.grayOne};
			h1 {
				color: ${LightTheme.colors.grayFive};
			}
			button {
				span {
					color: ${LightTheme.colors.grayFive};
				}
			}
		}
	}

	nav {
		display: flex;
		margin-bottom: ${LightTheme.spacing[5]}px;

		> button {
			flex: 1;
			background: none;
			border-radius: 0;
			text-transform: initial;
			color: ${LightTheme.colors.grayFive};

			padding: ${LightTheme.spacing[2]}px;
			border-bottom: 2px solid ${LightTheme.colors.grayTwo};
		}

		[data-active='true'] {
			border-bottom: 2px solid ${LightTheme.colors.blueOne};
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		li {
			background: ${LightTheme.colors.blueOne};
			font-size: 12px;
			padding: 4px;
			border-radius: ${LightTheme.borderRadius.base}px;
			color: ${LightTheme.colors.grayZero};
			margin-bottom: ${LightTheme.spacing[3]}px;
			margin-right: ${LightTheme.spacing[1]}px;
			display: flex;
			align-items: center;
			white-space: nowrap;

			button {
				all: revert;
				background: none;
				border: none;
				margin-left: ${LightTheme.spacing[1]}px;
				padding: 0;
				display: flex;
				cursor: pointer;

				span {
					color: ${LightTheme.colors.grayZero};
					font-size: 14px;
				}
			}
		}
	}

	main > div {
		border-bottom: 1px solid ${LightTheme.colors.grayOne};
		padding-bottom: ${LightTheme.spacing[5]}px;
		color: ${LightTheme.colors.grayFive};
		> div {
			display: flex;
			align-items: flex-start;
			width: 100%;

			input {
				flex: 1;
				width: 100%;
			}

			button {
				border: 1px solid ${LightTheme.colors.grayTwo};
				margin-left: ${props => (props.$isRightToLeft ? '0' : '8px')};
				margin-right: ${props => (props.$isRightToLeft ? '8px' : '0')};
			}
		}

		> button {
			padding: 0;
			span {
				margin-right: ${LightTheme.spacing[1]}px;
				color: ${LightTheme.colors.blueOne};
			}
		}
	}

	label {
		color: ${LightTheme.colors.graySeven};
	}

	input {
		background: ${LightTheme.colors.grayZero};
		color: ${LightTheme.colors.grayFive};
	}

	label ~ section {
		display: flex;
		width: 100%;
	}

	small > small {
		color: ${LightTheme.colors.redOne};
	}

	select {
		appearance: none;
		padding: ${LightTheme.spacing[2]}px;
		margin-bottom: ${LightTheme.spacing[3]}px;
		color: ${LightTheme.colors.grayFive};
		border: none;
		font-size: 14px;
		border-radius: ${LightTheme.borderRadius.base}px;
		width: 160px;
		margin-right: ${LightTheme.spacing[2]}px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: ${props => (props.$isRightToLeft ? 'left 10px center;' : 'right 10px center;')};
		background-size: 20px;
		border: 1px solid transparent;
		background-color: ${LightTheme.colors.grayZero};
		box-shadow:
			0 0 0 1px rgb(0 0 0 / 10%),
			0 2px 2px rgb(0 0 0 / 8%);

		+ input {
			width: 100%;
		}

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
			background-image: none;
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			section {
				background: ${DarkTheme.colors.grayThree};
				header {
					border-bottom: 1px solid ${DarkTheme.colors.grayFour};
					h1 {
						color: ${DarkTheme.colors.grayFive};
					}
					button {
						span {
							color: ${DarkTheme.colors.grayFive};
						}
					}
				}
			}

			nav {
				> button {
					color: ${DarkTheme.colors.grayFive};
					border-bottom: 2px solid ${DarkTheme.colors.grayFour};
				}
			}

			ul {
				li {
					color: ${DarkTheme.colors.grayOne};

					button {
						span {
							color: ${DarkTheme.colors.grayFive};
						}
					}
				}
			}

			main > div {
				border-bottom: 1px solid ${DarkTheme.colors.grayFour};
				color: ${DarkTheme.colors.grayFive};
				> div {
					button {
						border: 1px solid ${DarkTheme.colors.grayFour};
					}
				}
			}

			label {
				color: ${DarkTheme.colors.grayFive};
			}

			input {
				background: ${DarkTheme.colors.grayFour};
				color: ${DarkTheme.colors.grayFive};
			}

			select {
				color: ${DarkTheme.colors.grayFive};
				background-color: ${DarkTheme.colors.grayFour};
			}

			.translation-services {
				.custom-select__menu {
					background-color: var(--dark-theme-gray-3);
					color: var(--dark-theme-gray-1);
				}
				.custom-select__option--is-focused {
					background-color: var(--dark-theme-gray-2);
				}
				.custom-select__single-value {
					color: var(--dark-theme-gray-1);
				}
			}
		`}
`;

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean }, never>}
 */
const StyledRoomMembers = styled.div`
	> div {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		&:last-of-type {
			margin: 0;
		}
	}

	aside {
		display: flex;

		> div {
			&:first-of-type {
				margin-right: ${LightTheme.spacing[2]}px;
			}
		}

		p {
			margin: 0;
			padding: 0;
			color: ${LightTheme.colors.grayFive};
			font-size: 14px;

			+ p {
				color: ${LightTheme.colors.grayFour};
			}
		}

		~ div {
			margin-left: auto;
		}

		[size='32'] {
			font-size: 10px;
			font-weight: 500;
		}
	}

	${props =>
		props.$isDarkMode &&
		css`
			aside p {
				color: ${DarkTheme.colors.grayFive};
			}

			aside p + p {
				color: ${DarkTheme.colors.grayFour};
			}
		`}
`;

const StyledInvitedParticipants = styled.div`
	div {
		display: flex;
		justify-content: space-between;
		align-items: center;

		button {
			padding: 4px 16px !important;
		}

		p {
			margin: 0;
			padding: 0;
		}
	}

	& > * + * {
		padding-top: var(--spacing-xl);
	}
`;

const TokenInputs = ({ tokens, onRemove }) => {
	return (
		<ul>
			{tokens.map(item => (
				<li key={item.id}>
					{item.value}
					<CustomButton onClick={() => onRemove(item)} icon='close' />
				</li>
			))}
		</ul>
	);
};

const tabNames = {
	FAMILY_FRIENDS: 1,
	ADD_PEOPLE: 2,
	TRANSLATION_SERVICES: 3,
	SENT_INVITES: 4,
};

/**
 * @param {object} props
 * @param {(message) => void} props.onDismiss
 * @param {string} [props.roomId]
 * @param {string} props.position
 * @param {object} props.configurations
 * @param {React.Dispatch<React.SetStateAction<any>>} props.setFailedInvites
 */
const InviteParticipantsView = ({ onDismiss, roomId, configurations = {}, setFailedInvites, position }) => {
	const conference = useConference();
	const intl = useIntl();
	const [email, setEmail] = useState('');
	const [emails, setEmails] = useState([]);
	const [emailError, setEmailError] = useState(null);
	const [error, setError] = useState(null);
	const [dialInNumbers, setDialInNumbers] = useState([]);
	const [accessCode, setAccessCode] = useState(null);
	const [dialOutNumbers, setDialOutNumbers] = useState([]);
	const [dialOutPhoneError, setDialOutPhoneError] = useState(null);
	const [sipInfo, setSipInfo] = useState(null);
	const [sipError, setSipError] = useState(null);
	const [invitationNote, setInvitationNote] = useState('');
	const [isCancelInviteDisabled, setIsCancelInviteDisabled] = useState(false);
	const conferenceParticipants = useConferenceParticipants();

	const [inviteLinkText, setInviteLinkText] = useState(intl.formatMessage({ id: 'copy' }));

	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [phoneNumberError, setPhoneNumberError] = useState(null);

	const [selectedCountry, setSelectedCountry] = useState(countriesData.US);
	const [dialOutCountryCode, setDialOutCountryCode] = useState(countriesData.US);
	const [selectedTargetLanguage, setSelectedTargetLanguage] = useState({ id: '', name: '' });
	const [selectedSourceLanguage, setSelectedSourceLanguage] = useState({ id: '', name: '' });
	const [selectedLanguageLine, setSelectedLanguageLine] = useState({
		label: '',
		value: '',
		index: 999,
	});
	const { ref: phoneInputRef, maskRef: phoneInputMaskRef } = useIMask({ mask: selectedCountry.phonePattern, lazy: false });
	const { ref: dialOutPhoneInputRef, maskRef: dialOutPhoneInputMaskRef } = useIMask({
		mask: dialOutCountryCode.phonePattern,
		lazy: false,
	});

	const copyLinkTimeoutRef = useRef(null);
	const healthSystems = useSelector(state => state.healthSystems);

	const [roomMembers, setRoomMembers] = useState([]);
	const [participants, setParticipants] = useState([]);
	const [tab, setTab] = useState(tabNames.ADD_PEOPLE);
	const [globoLanguages, setGloboLanguages] = useState([]);
	const [activeAssignment, setActiveAssignment] = useState(null);
	const [amnPhone, setAmnPhone] = useState(null);
	const [authCodeLanguageLine, setAuthCodeLanguageLine] = useState(null);
	const userSession = useSelector(state => state.user.userSession);
	const conferenceConfigs = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const socketFunctions = useContext(SocketFunctionsContext);
	const socket = useContext(SocketContext);
	const [translationServiceBaseUrl, setTranslationServiceBaseUrl] = useState('');

	const getDialInNumbers = numbers => {
		return numbers.map(({ number, state }) => {
			let formattedNumber = '';
			if (isValidNumberFromSip(String(number), state)) {
				const parsedNumber = parsePhoneNumber(String(number), state);
				formattedNumber = `+${parsedNumber.countryCallingCode} ${parsedNumber.formatNational()}`;
			} else {
				formattedNumber = String(number);
			}
			return {
				number: formattedNumber,
			};
		});
	};

	useEffect(() => {
		if (roomId) {
			const fetchRoomMembers = async () => {
				const room = await getRoomMembers(userSession.healthSystem.id, roomId);
				setRoomMembers(room.roomMembers);
			};
			fetchRoomMembers();
		}
	}, [roomId, userSession.healthSystem.id]);

	useEffect(() => {
		const fetchGlobalLanguages = async (url, location) => {
			if (!url) {
				return;
			}
			const response = await getGloboLanguages(url, location);
			if (response.error) {
				setError(response.error.message);
			} else {
				setGloboLanguages(response.languages);
				setSelectedSourceLanguage(response.languages[0]);
				setSelectedTargetLanguage(response.languages[1]);
			}
		};
		const fetchTranslationProviders = async () => {
			const location = getTreeHierarchyParams();
			const interpreterResponse = await getLowestLevelInterperterConfiguration(location);
			let response = {};
			if (interpreterResponse.error) {
				setError(interpreterResponse.error.message);
				return;
			}
			if (!interpreterResponse) {
				return;
			}
			response = interpreterResponse.result;
			const baseUrl = isJSON(response.configJson) ? JSON.parse(response.configJson).baseUrl : '';
			setTranslationServiceBaseUrl(baseUrl);
			if (response.configurationTypeId === configurationTypeIds.GLOBO) {
				fetchGlobalLanguages(baseUrl, location);
			}
			if (response.configurationTypeId === configurationTypeIds.AMN) {
				const phoneNumberAMN = isJSON(response.configJson) ? JSON.parse(response.configJson).sipEndpoint : '';
				setAmnPhone(phoneNumberAMN);
			}
			if (response.configurationTypeId === configurationTypeIds.LANGUAGE_LINE) {
				const authCodeLanguageLine = isJSON(response.configJson) ? JSON.parse(response.configJson).authCode : '';
				setAuthCodeLanguageLine(authCodeLanguageLine);
			}
		};
		fetchTranslationProviders();
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		const handleAssignmentStatus = data => {
			const assignment = { ...data, uuid: activeAssignment?.uuid };
			setActiveAssignment(assignment);
		};
		socket.on(SocketEvents.Conference.INTERPRETER_RESOURCE_UPDATED, handleAssignmentStatus);
		return () => {
			socket.off(SocketEvents.Conference.INTERPRETER_RESOURCE_UPDATED, handleAssignmentStatus);
		};
	}, []);

	useEffect(() => {
		const getSip = async () => {
			const response = await getSipInfo(conference.conferenceId);
			if (response.error) {
				setSipError(<small>{translate('sipNotAvailable')}</small>);
				return;
			}
			setSipInfo(response);
			setAccessCode(response.accessCode);
			const numbers = getDialInNumbers(response.numbers);
			setDialInNumbers(numbers ?? []);
		};
		if (
			(configurations.isDialInOn || configurations.isDialOutOn || configurations.isTranslationServicesOn) &&
			userSession.healthSystem.id
		) {
			getSip();
		}
		return () => {
			if (copyLinkTimeoutRef.current) {
				clearTimeout(copyLinkTimeoutRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (configurations.isInviteViaSmsOn) {
			phoneInputMaskRef.current.mask = selectedCountry.phonePattern;
			phoneInputMaskRef.current.unmaskedValue = '';
		}
	}, [selectedCountry, phoneInputMaskRef]);

	const getRoleName = visitorTypeId => {
		return visitorTypeId === VisitorType.FAMILY_MEMBER ? translate('familyMember') : translate('visitor');
	};

	const toggleParticipants = userIntId => {
		let newParticipants;
		const participantExists = participants.find(p => p.objectId === userIntId);
		if (participantExists) {
			newParticipants = participants.filter(p => p.objectId !== userIntId);
		} else {
			newParticipants = [...participants, { objectId: userIntId, objectType: enums.ObjectTypes.USER }];
		}
		setParticipants(newParticipants);
	};

	const isValidEmail = value => {
		if (!value) {
			return false;
		}
		if (emails.some(e => e.value === value)) {
			setEmailError(<small>{translate('emailAlreadyExists')}</small>);
			return false;
		}
		if (!emailValidationRegEx.test(value)) {
			setEmailError(<small>{translate('pleaseEnterValidEmail')}</small>);
			return false;
		}

		return true;
	};

	const addEmail = event => {
		const { value } = event.target;
		if (event.which !== KeyCodes.ENTER) {
			return;
		}

		if (!isValidEmail(value)) {
			return;
		}

		setEmails(prevState => [...prevState, { id: prevState.length, value }]);
		setEmail('');
		setEmailError('');
	};

	const isValidPhoneNumber = value => {
		if (!value) {
			return false;
		}
		if (phoneNumbers.some(e => e.value === value)) {
			setPhoneNumberError(<small>{translate('phoneNumberExists')}</small>);
			return false;
		}
		if (!phoneInputMaskRef.current.masked.isComplete) {
			setPhoneNumberError(<small>{translate('pleaseEnterValidNumber')} </small>);
			return false;
		}

		return true;
	};

	const isValidSip = value => {
		if (!value) {
			return false;
		}
		if (dialOutNumbers.some(e => e.value === value)) {
			setDialOutPhoneError(<small>{translate('phoneNumberExists')} </small>);
			return false;
		}
		if (!dialOutPhoneInputMaskRef.current.masked.isComplete) {
			setDialOutPhoneError(<small>{translate('pleaseEnterValidNumber')} </small>);
			return false;
		}

		return true;
	};

	const addPhoneNumber = event => {
		const { value } = event.target;
		if (event.which !== KeyCodes.ENTER) {
			return;
		}

		if (!isValidPhoneNumber(value)) {
			return;
		}

		const newPhoneNumber = {
			id: phoneNumbers.length,
			value: phoneInputMaskRef.current.value,
			unmaskedValue: `+${phoneInputMaskRef.current.unmaskedValue}`,
		};
		setPhoneNumbers(prevState => [...prevState, newPhoneNumber]);
		phoneInputMaskRef.current.unmaskedValue = '';
		setPhoneNumberError('');
	};

	const addDialOutNumber = event => {
		const { value } = event.target;
		if (event.which !== KeyCodes.ENTER) {
			return;
		}

		if (!isValidSip(value)) {
			return;
		}

		const newPhoneNumber = {
			id: dialOutNumbers.length,
			value: dialOutPhoneInputMaskRef.current.value,
			unmaskedValue: `+${dialOutPhoneInputMaskRef.current.unmaskedValue}`,
		};
		setDialOutNumbers(prevState => [...prevState, newPhoneNumber]);
		dialOutPhoneInputMaskRef.current.unmaskedValue = '';
		setDialOutPhoneError('');
	};

	const copyToClipboard = async () => {
		const updateInviteLinkText = text => {
			setInviteLinkText(text);
			if (copyLinkTimeoutRef.current) {
				clearTimeout(copyLinkTimeoutRef.current);
			}
			copyLinkTimeoutRef.current = setTimeout(() => {
				setInviteLinkText(intl.formatMessage({ id: 'copy' }));
			}, 3000);
		};

		try {
			// @ts-ignore
			const { state } = await navigator.permissions.query({ name: 'clipboard-write' });
			if (state === 'granted' || state === 'prompt') {
				await navigator.clipboard.writeText(conference.link);
				updateInviteLinkText(intl.formatMessage({ id: 'copied' }));
			}
		} catch (err) {
			updateInviteLinkText(intl.formatMessage({ id: 'failedToCopyLink' }));
			// eslint-disable-next-line no-console
			console.warn('Failed to copy conference link!', err);
		}
	};

	const removeEmail = ({ id }) => {
		setEmails(prevState => [...prevState.filter(item => item.id !== id)]);
	};

	const removePhoneNumber = ({ id }) => {
		setPhoneNumbers(prevState => [...prevState.filter(item => item.id !== id)]);
	};

	const onCountryChanged = code => {
		setSelectedCountry(countriesData[code]);
	};

	const onDialOutCountryChanged = code => {
		setDialOutCountryCode(countriesData[code]);
	};

	const onSubmit = async () => {
		const phoneNumberOnInput = phoneInputMaskRef?.current?.unmaskedValue?.length - (selectedCountry.phonePrefix.length - 1);
		const emailOnInput = email.length;
		if (!emailOnInput && !phoneNumberOnInput && !emails?.length && !phoneNumbers?.length && !participants?.length) {
			return;
		}

		const newEmails = [...emails];
		const newPhoneNumbers = [...phoneNumbers];

		if (emailOnInput) {
			if (!isValidEmail(email)) {
				return;
			}
			newEmails.push({ value: email });
		}

		if (phoneNumberOnInput) {
			if (!isValidPhoneNumber(phoneInputMaskRef.current.unmaskedValue)) {
				return;
			}
			newPhoneNumbers.push({ unmaskedValue: `+${phoneInputMaskRef.current.unmaskedValue}` });
		}

		const fullStops = invitationNote.split('.');
		let newInvitationNote = '';
		if (fullStops.length > 1 && invitationNote[invitationNote.length - 1] === '.') {
			const lastFullStopIndex = invitationNote.lastIndexOf('.');
			newInvitationNote = invitationNote.substring(0, lastFullStopIndex);
		}

		const response = await conference.inviteParticipants({
			participants,
			emailList: newEmails.map(e => e.value),
			phoneNumberList: newPhoneNumbers.map(p => p.unmaskedValue),
			siteName: APP_CONFIG.URL.localApiBasePath,
			message: !newInvitationNote ? invitationNote : newInvitationNote,
			healthSystemName: userSession.healthSystem.name,
		});
		if (response.failedInvitationToParticipants) {
			const failedUsers = [];
			response.failedInvitationToParticipants.forEach(f => {
				const visitor = roomMembers.find(member => member.userIntId === f.objectId);
				if (visitor) {
					failedUsers.push({ id: visitor.userId, objectId: visitor.userIntId, name: visitor.name, reason: f.reason });
				}
			});
			setFailedInvites(failedUsers);
		}
		const invitedParticipantsResponse = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
		});
		if (invitedParticipantsResponse) {
			conferenceConfigs.setInvitedParticipants(invitedParticipantsResponse);
		}
		onDismiss();
	};

	const inviteDialOut = async () => {
		const newDialOutNumbers = [...dialOutNumbers];
		newDialOutNumbers.push({ unmaskedValue: `+${dialOutPhoneInputMaskRef.current.unmaskedValue}` });
		const response = await dialOut(newDialOutNumbers[0].unmaskedValue, conference.conferenceId);
		if (response.error) {
			setError(translate('phoneNotReachable'));
		} else {
			removeParticipantOnNoAnswer(response.participantId);
			onDismiss();
		}
	};

	const getLanguageLineNumber = () => {
		const defaultNumber = 'test-audio-siptestlls@sip.languageline.com';
		return authCodeLanguageLine === defaultNumber
			? defaultNumber
			: selectedLanguageLine.value.replace('YOUR-AUTHCODE-HERE', authCodeLanguageLine);
	};

	const inviteDialOutTranslator = async () => {
		if (authCodeLanguageLine && !selectedLanguageLine.value) {
			return;
		}
		const numberToDial = amnPhone ?? getLanguageLineNumber();
		const response = await dialOutTranslator(numberToDial, conference.conferenceId);
		if (response.error) {
			setError(translate('phoneNotReachable'));
		} else {
			removeParticipantOnNoAnswer(response.participantId, intl.formatMessage({ id: 'unableToConnectInterpreter' }));
			onDismiss();
		}
	};

	const removeParticipantOnNoAnswer = (participantId, message = '') => {
		setTimeout(() => {
			const participant = conference.participants.get(participantId);
			if (participant) {
				conference.removeParticipant(participantId);
				onDismiss(message);
			}
		}, 60000);
	};

	const onTargetLanguageSelect = event => {
		const foundItem = globoLanguages.find(language => language.id.toString() === event.target.value);
		if (!foundItem) {
			return;
		}
		setSelectedTargetLanguage(foundItem);
	};

	const onLanguageLineSelect = event => {
		const foundItem = languages.find(language => language.value === event.value);
		if (!foundItem) {
			return;
		}
		setSelectedLanguageLine(foundItem);
	};

	const inviteInterpreter = async () => {
		const uuid = v4();
		const params = {
			healthSystemId: userSession.healthSystem.id,
			inviteUrl: `${conference.link}?interpreterId=${uuid}`,
			sourceLanguage: 'English',
			targetLanguage: selectedTargetLanguage.name,
			callbacks: [
				{
					url: `${APP_CONFIG.URL.gatewayApiBasePath}/v1/virtual-sessions/${conference.conferenceId}/interpreter/${uuid}/callback`,
					statuses: ['resource-assigned', 'in-progress', 'completed'],
				},
			],
			baseUrl: translationServiceBaseUrl,
		};
		const location = getTreeHierarchyParams();
		const response = await createAssignment(params, location);
		if (response?.error) {
			setError(response.error.message);
			return;
		}
		await socketFunctions.sendInterpreterAssignmentRequest({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			methodType: 'create',
			data: {
				uid: uuid,
				healthSystemId: userSession.healthSystem.id,
				companyId: getCompanyId(),
			},
		});
		setActiveAssignment({ status: AssignmentStatus.NEW, id: response.id, uuid });
	};

	const cancelRequest = async () => {
		const deleteParams = {
			healthSystemId: userSession.healthSystem.id,
			assignmentId: activeAssignment.id,
			baseUrl: translationServiceBaseUrl,
		};
		const location = getTreeHierarchyParams();
		const response = await deleteAssignment(deleteParams, location);
		if (response?.error) {
			setError(response.error.message);
			return;
		}

		await socketFunctions.sendInterpreterAssignmentRequest({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			methodType: 'cancel',
			data: {
				uid: activeAssignment.uuid,
				healthSystemId: userSession.healthSystem.id,
				companyId: getCompanyId(),
			},
		});
		setActiveAssignment(null);
	};

	const removeInvitationLink = async links => {
		setIsCancelInviteDisabled(true);
		const response = await socketFunctions.removeInvitationLinks({
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			links,
		});
		if (response.ok) {
			setIsCancelInviteDisabled(false);
			const invitedParticipantsResponse = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
				conferenceId: conference.conferenceId,
				participantId: localParticipant.id,
			});
			conferenceConfigs.setInvitedParticipants(invitedParticipantsResponse);
			if (invitedParticipantsResponse.links?.length === 0) {
				setTab(tabNames.ADD_PEOPLE);
			}
		}
	};

	const showInviteButton = () =>
		!activeAssignment || [AssignmentStatus.CANCELED, AssignmentStatus.COMPLETED].includes(activeAssignment?.status);

	const getRequestStatusDescription = status => {
		let result = '';
		switch (status) {
			case AssignmentStatus.CANCELED: {
				result = intl.formatMessage({ id: 'canceled' });
				break;
			}
			case AssignmentStatus.COMPLETED: {
				result = intl.formatMessage({ id: 'completed' });
				break;
			}
			case AssignmentStatus.IN_PROGRESS: {
				result = intl.formatMessage({ id: 'inProgress' });
				break;
			}
			case AssignmentStatus.NEW: {
				result = intl.formatMessage({ id: 'new' });
				break;
			}
			case AssignmentStatus.RESOURCE_ASSIGNED: {
				result = intl.formatMessage({ id: 'resourceAssigned' });
				break;
			}
			default: {
				result = 'N/A';
			}
		}
		return result;
	};

	const showOtherPeopleTab = () =>
		configurations.isMeetingURLOn ||
		configurations.isInviteViaEmailOn ||
		configurations.isInviteViaSmsOn ||
		configurations.isDialInOn ||
		configurations.isDialOutOn;

	const showSentInvitesTab = () => conferenceConfigs.invitedParticipants?.links?.length > 0 && configurations.isCancelInviteOn;

	useEffect(() => {
		if (showOtherPeopleTab()) {
			setTab(tabNames.ADD_PEOPLE);
		} else if (showSentInvitesTab()) {
			setTab(tabNames.SENT_INVITES);
		} else if (showTranslationServiesTab()) {
			setTab(tabNames.TRANSLATION_SERVICES);
		} else if (showFamilyMembersTab()) {
			setTab(tabNames.FAMILY_FRIENDS);
		}
	}, [roomMembers, roomId]);

	useEffect(() => {
		const getInvitationLinks = async () => {
			if (tab === tabNames.SENT_INVITES) {
				const response = await socket.emitWithAck(SocketEvents.Conference.GET_INVITATION_LINKS, {
					conferenceId: conference.conferenceId,
					participantId: localParticipant.id,
				});
				conferenceConfigs.setInvitedParticipants(response);
			}
		};
		getInvitationLinks();
	}, [tab]);

	const showTranslationServiesTab = () => translationServiceBaseUrl && configurations.isTranslationServicesOn;

	const showFamilyMembersTab = () => roomId && roomMembers?.length > 0;

	const getTreeHierarchyParams = () => {
		const sector = findSectorById(healthSystems.treeData.tree, roomId);
		const { hospitalId, departmentId, floorId } = sector;
		return { healthSystemId: userSession.healthSystem.id, hospitalId, departmentId, floorId };
	};

	return (
		<StyledInviteParticipants
			id='stress-test-add-people-modal'
			className={classNames(position ? 'invite-participants-view' : '')}
			$isRightToLeft={conferenceConfigs.isRightToLeft}
			$isDarkMode={conferenceConfigs.isDarkMode}>
			<Modal position={position} onDismiss={onDismiss} title={intl.formatMessage({ id: 'multipartyCalling' })}>
				<Modal.Content>
					<nav>
						{showOtherPeopleTab() && (
							<CustomButton
								marginRight={0}
								className='flex-justify-center'
								active={tab === tabNames.ADD_PEOPLE}
								onClick={() => setTab(tabNames.ADD_PEOPLE)}
								text={translate('addPeople')}
							/>
						)}
						{showSentInvitesTab() && (
							<CustomButton
								marginRight={0}
								className='flex-justify-center'
								active={tab === tabNames.SENT_INVITES}
								onClick={() => setTab(tabNames.SENT_INVITES)}
								text={translate('sentInvites')}
							/>
						)}
						{showTranslationServiesTab() && (
							<CustomButton
								marginRight={0}
								className='flex-justify-center'
								active={tab === tabNames.TRANSLATION_SERVICES}
								onClick={() => setTab(tabNames.TRANSLATION_SERVICES)}
								text={translate('requestInterpreterServices')}
							/>
						)}
						{showFamilyMembersTab() && (
							<CustomButton
								marginRight={0}
								className='flex-justify-center'
								active={tab === tabNames.FAMILY_FRIENDS}
								onClick={() => setTab(tabNames.FAMILY_FRIENDS)}
								text={translate('familyFriends')}
							/>
						)}
					</nav>
					{!amnPhone &&
						!authCodeLanguageLine &&
						translationServiceBaseUrl &&
						configurations.isTranslationServicesOn &&
						tab === tabNames.TRANSLATION_SERVICES && (
							<div className='translation-services'>
								<div>
									<CustomSelect
										labelClassName='full-width flex'
										className='margin-left-auto'
										label='Target Language'
										name='selectLanguage'
										items={globoLanguages.filter(item => item.id !== selectedSourceLanguage.id)}
										valueField='id'
										textField='name'
										placeholder='Select Language'
										value={selectedTargetLanguage.id}
										onSelect={onTargetLanguageSelect}
									/>
								</div>
								{activeAssignment?.status && (
									<div>
										{translate('status')}: {getRequestStatusDescription(activeAssignment.status)}
									</div>
								)}
							</div>
						)}

					{authCodeLanguageLine &&
						tab === tabNames.TRANSLATION_SERVICES &&
						translationServiceBaseUrl &&
						configurations.isTranslationServicesOn && (
							<div className='translation-services'>
								<div className='language-label'>
									<p>{translate('language')}</p>
								</div>
								<div className='language-label-select'>
									<Select
										style={{ minWidth: '300px' }}
										value={selectedLanguageLine}
										placeholder='Select Language'
										classNamePrefix='custom-select'
										options={languages}
										components={<i className='material-icons-outlined'>arrow_drop_down</i>}
										onChange={onLanguageLineSelect}
									/>
								</div>
								<div className='interpreter-desc'>
									{amnPhone && <p>{translate('inviteInterpreterDesc')}</p>}
									{authCodeLanguageLine && <p>{translate('languageLineDesc')}</p>}
								</div>
							</div>
						)}
					{amnPhone && tab === tabNames.TRANSLATION_SERVICES && <p>{translate('inviteInterpreterDesc')}</p>}
					{tab === tabNames.FAMILY_FRIENDS && (
						<StyledRoomMembers
							$isDarkMode={conferenceConfigs.isDarkMode}
							style={{ display: roomId && tab === tabNames.FAMILY_FRIENDS ? 'block' : 'none' }}>
							{roomMembers.map(({ name, visitorTypeId, userIntId }) => (
								<div>
									<aside>
										<Avatar size={32} src={null} name={name} />
										<div>
											<p>{name}</p>
											<p>{getRoleName(visitorTypeId)}</p>
										</div>
									</aside>
									<Checkbox onChange={() => toggleParticipants(userIntId)} />
								</div>
							))}
						</StyledRoomMembers>
					)}
					{tab === tabNames.SENT_INVITES && (
						<StyledInvitedParticipants>
							{conferenceConfigs.invitedParticipants.links?.map(invitedParticipant => (
								<div key={invitedParticipant.destination}>
									<p>{invitedParticipant.destination}</p>
									<Button
										disabled={isCancelInviteDisabled}
										type='button'
										variant={ButtonType.CANCEL}
										onClick={() =>
											removeInvitationLink([
												{ destination: invitedParticipant.destination, invitationSecret: invitedParticipant.invitationSecret },
											])
										}>
										{translate('cancelInvite')}
									</Button>
								</div>
							))}
						</StyledInvitedParticipants>
					)}
					<div style={{ display: tab === tabNames.ADD_PEOPLE ? 'block' : 'none' }}>
						{conference?.link && configurations.isMeetingURLOn && (
							<main>
								<Input>
									<Input.Label>{translate('directLink')}</Input.Label>
									<div>
										<Input.Field value={conference.link} onKeyPress={addEmail} readOnly={true} />
										<Button type='button' onClick={copyToClipboard}>
											{inviteLinkText}
										</Button>
									</div>
									<Input.Description>{translate('sendLinkToPeople')}</Input.Description>
								</Input>
							</main>
						)}
						{configurations.isInviteViaEmailOn && (
							<Input>
								<Input.Label>{translate('inviteViaEmail')}</Input.Label>
								{emails?.length > 0 && <TokenInputs tokens={emails} onRemove={removeEmail} />}
								<Input.Field
									placeholder={intl.formatMessage({ id: 'addEmail' })}
									value={email}
									onKeyPress={addEmail}
									onChange={event => setEmail(event.target.value)}
								/>
								<Input.Description>{emailError || intl.formatMessage({ id: 'pleaseEnterToAddEmail' })}</Input.Description>
							</Input>
						)}
						{configurations.isInviteViaSmsOn && (
							<Input>
								<Input.Label>{translate('inviteViaSms')}</Input.Label>
								{phoneNumbers?.length > 0 && <TokenInputs tokens={phoneNumbers} onRemove={removePhoneNumber} />}
								<section>
									<select value={selectedCountry?.code} onChange={ev => onCountryChanged(ev.target.value)} disabled={true}>
										<option hidden value={selectedCountry?.code} label={selectedCountry?.code} />
										{Object.values(countriesData).map(({ code, name, phonePrefix }) => (
											<option key={code} value={code} label={`${name} ${phonePrefix}`} />
										))}
									</select>
									<Input.Field
										ref={phoneInputRef}
										placeholder={intl.formatMessage({ id: 'inviteViaSms' })}
										onKeyPress={addPhoneNumber}
									/>
								</section>
								<Input.Description>
									{phoneNumberError || intl.formatMessage({ id: 'pressEnterToAddPhoneNumber' })}
								</Input.Description>
							</Input>
						)}
						{configurations.isDialInOn && userSession.healthSystem.id && sipInfo && !sipError && (
							<Input>
								<Input.Label>{translate('joinViaPhone')}</Input.Label>

								<section>
									<div className='sip-info-container'>
										{dialInNumbers && accessCode && (
											<>
												<div>
													<Input.Description>{intl.formatMessage({ id: 'dialIn' })}</Input.Description>
													{dialInNumbers.map(({ number }) => (
														<Input.Field key={number} readOnly value={number} className='sip-input' />
													))}
												</div>
												<div>
													<Input.Description>{intl.formatMessage({ id: 'accessCode' })}</Input.Description>
													<Input.Field readOnly value={`${accessCode}#`} className='sip-input' />
												</div>
											</>
										)}
										{!dialInNumbers && !accessCode && <div>{intl.formatMessage({ id: 'sipNotAvailable' })}</div>}
									</div>
								</section>
							</Input>
						)}
						{configurations.isDialOutOn && userSession.healthSystem.id && sipInfo && !sipError && (
							<Input>
								<Input.Label>{intl.formatMessage({ id: 'callPhoneNumber' })}</Input.Label>
								<section className='dial-out'>
									<select
										value={dialOutCountryCode?.code}
										onChange={ev => onDialOutCountryChanged(ev.target.value)}
										disabled={true}>
										<option hidden value={dialOutCountryCode?.code} label={dialOutCountryCode?.code} />
										{Object.values(countriesData).map(({ code, name, phonePrefix }) => (
											<option key={code} value={code} label={`${name} ${phonePrefix}`} />
										))}
									</select>
									<Input.Field
										ref={dialOutPhoneInputRef}
										placeholder={intl.formatMessage({ id: 'callPhoneNumber' })}
										onKeyPress={addDialOutNumber}
									/>
									<Button type='submit' variant={ButtonType.SUBMIT} onClick={inviteDialOut}>
										{translate('callNumber')}
									</Button>
								</section>
								<Input.Description>
									{dialOutPhoneError || intl.formatMessage({ id: 'pressEnterToAddPhoneNumber' })}
								</Input.Description>
							</Input>
						)}
						<Input>
							<Input.Label>{intl.formatMessage({ id: 'invitationNote' })}</Input.Label>
							<Input.Field
								type='text'
								value={invitationNote}
								onChange={e => setInvitationNote(e.target.value)}
								placeholder={intl.formatMessage({ id: 'invitationNotePlaceholder' })}
								maxLength={160}
							/>
							<Input.Description>{translate('invitationNoteDescription')}</Input.Description>
						</Input>
					</div>
				</Modal.Content>
				<Modal.Actions>
					{tab !== tabNames.TRANSLATION_SERVICES && tab !== tabNames.SENT_INVITES && (
						<Button type='submit' onClick={onSubmit} variant={ButtonType.SUBMIT}>
							{translate('sendInvite')}
						</Button>
					)}
					{tab === tabNames.TRANSLATION_SERVICES && (
						<>
							{!amnPhone && !authCodeLanguageLine && showInviteButton() && (
								<Button type='submit' onClick={inviteInterpreter} variant={ButtonType.SUBMIT}>
									{translate('inviteInterpreter')}
								</Button>
							)}
							{(amnPhone || authCodeLanguageLine) && (
								<Button type='submit' onClick={inviteDialOutTranslator} variant={ButtonType.SUBMIT}>
									{translate('inviteInterpreter')}
								</Button>
							)}
							{!amnPhone && !conferenceParticipants.some(item => item.interpreterId) && !showInviteButton() && (
								<Button type='submit' onClick={cancelRequest} variant={ButtonType.SUBMIT}>
									{translate('inviteInterpreter')}
								</Button>
							)}
						</>
					)}
					{tab === tabNames.SENT_INVITES && (
						<Button
							disabled={isCancelInviteDisabled}
							type='submit'
							variant={ButtonType.SUBMIT}
							onClick={() => removeInvitationLink(conferenceConfigs.invitedParticipants.links)}>
							{translate('cancelAllInvites')}
						</Button>
					)}
				</Modal.Actions>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</Modal>
		</StyledInviteParticipants>
	);
};

export default InviteParticipantsView;
