import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Mic } from '@solaborate/calls/webrtc';
import { conference as Conference, enums } from '@solaborate/calls';
import { useDispatch, useSelector } from 'react-redux';
import LocalParticipant from 'calls/LocalParticipant.js';
import {
	useConference,
	useConferenceConfigurations,
	useConferenceParticipants,
	useConferenceState,
	useControllerTracks,
	useLocalParticipant,
} from 'calls/hooks/index.js';
import HealthMeasurements from 'containers/HealthMeasurementTabs/HealthMeasurements.jsx';
import {
	MeasureDeviceType,
	MedicalInfoTypes,
	UserRoles,
	WearablesStatus,
	WearableProviderIds,
	UserTypes,
	TvSettingTypesId,
	CompanionModeId,
} from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import AddDiagnosesOrProcedures from 'containers/AddDiagnosesOrProcedures.jsx';
import AddNotes from 'containers/AddNotes.jsx';
import PrescriptionsWrapper from 'containers/Prescriptions/PrescriptionsWrapper.jsx';
import { ControlsActions } from 'calls/enums/index.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import CallWrapUp from 'containers/CallWrapUp.jsx';
import AddPTReport from 'containers/AddPTReport.jsx';
import LiveExaminationKit from 'containers/LiveExaminations/LiveExaminationKit.jsx';
import SocketEvents from 'constants/socket-events.js';
import PatientMain from 'views/Patients/Partials/PatientMain.jsx';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { VisitStatus } from 'constants/visitEnums.js';
import { MedicalDevicesConfigsList, RoundingSettings } from 'constants/configurationEnums.js';
import RpmMeasurements from 'containers/Rpm/RpmMeasurements.jsx';
import { TelemetryIcon } from 'calls/icons/index.js';
import { getRoleConfigurationValue, getAge, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { getExternalDevices } from 'api/lifeSignals.js';
import { getActiveEncounter, setEncounterId } from 'api/whiteboard.js';
import Alert from 'components/Alert.jsx';
import AddCareEventsForm from 'components/AddCareEventsForm.jsx';
import LightTheme from 'calls/styles/LightTheme.js';
import Whiteboard from 'containers/Monitoring/Whiteboard.jsx';
import RoomSign from 'containers/Rounding/RoomSign/RoomSign.jsx';
import { getPatientRpmProgram } from 'api/rpm.js';
import Button from 'components/Button.jsx';
import { editWhiteboardControl, getWhiteboardControls } from 'api/whiteboardControls.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import DisplayControl from 'containers/Monitoring/DisplayControl.jsx';
import { getCompanionDevicesByRoom } from 'api/devices.js';
import { APP_CONFIG } from 'constants/global-variables.js';

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant
 * @param {(visitStatus: VisitStatus[keyof typeof VisitStatus]) => void}  props.submitVisit
 */
const HealthDataContainer = ({ mainParticipant, submitVisit, roomId }) => {
	const socket = useContext(SocketContext);
	const socketFunctions = useContext(SocketFunctionsContext);
	const conference = useConference();
	const conferenceParticipants = useConferenceParticipants();
	const conferenceState = useConferenceState();
	const conferenceConfigurations = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const tracks = useControllerTracks(
		mainParticipant instanceof LocalParticipant ? mainParticipant.localTrackController : mainParticipant.remoteTrackController
	);
	const dispatch = useDispatch();
	const myPatients = useSelector(state => state.myPatients);
	const isRequestUpdated = useSelector(state => state.myDoctors.isRequestUpdated);
	const prevPeerHasAudioRef = useRef(false);
	const [iframeSrc, setIframeSrc] = useState(null);
	const [patientInfo, setPatientInfo] = useState(null);
	const [notes, setNotes] = useState([]);
	const [diagnoses, setDiagnoses] = useState([]);
	const [prescriptions, setPrescriptions] = useState([]);
	const [procedures, setProcedures] = useState([]);
	const [rpmProgram, setRpmProgram] = useState(null);
	const [lifeSignalsWearableId, setLifeSignalsWearableId] = useState(null);
	const [error, setError] = useState(null);
	const [whiteboardDisplayControls, setWhiteboardDisplayControls] = useState([]);
	const [isWhiteboardAssigned, setIsWhiteboardAssigned] = useState(false);
	const [displayControlLoading, setDisplayControlLoading] = useState(false);

	const {
		isDiagnosesVisible,
		isProceduresVisible,
		isNotesVisible,
		isPrescriptionsVisible,
		isPhysicalExercisesVisible,
		isConversationModalVisible,
		isCareEventsFormVisible,
	} = conferenceConfigurations.medicalDataControls;
	const { roundingConfigurations } = conferenceConfigurations;
	const intl = useIntl();

	const getPatientDetails = () =>
		conferenceParticipants.reduce((result, participant) => {
			if (participant.role === UserTypes.PATIENT || participant instanceof RemoteHelloParticipant) {
				return { objectType: participant.objectType, objectId: participant.objectId };
			}
			return result;
		}, null);

	const isLiveExaminationConfigsOn = () =>
		(getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MedicalDevices) &&
			getSomeRoleConfigurationsValues(roundingConfigurations, MedicalDevicesConfigsList)) ||
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ExamKit);

	useEffect(() => {
		const onWhiteboardDisplayControlUpdated = data => {
			if (roomId !== data?.roomId) {
				return;
			}
			const newClonedData = _.cloneDeep(whiteboardDisplayControls);
			const itemIndex = whiteboardDisplayControls.findIndex(
				item => item?.settingTypeId === TvSettingTypesId[data?.settingType?.toUpperCase()]
			);

			if (itemIndex !== -1) {
				newClonedData[itemIndex] = { ...newClonedData[itemIndex], ...data };
				setWhiteboardDisplayControls(newClonedData);
			}
		};
		socket.on(SocketEvents.HelloDevice.TV_CONTROL_CHANGED, onWhiteboardDisplayControlUpdated);
		return () => {
			socket.off(SocketEvents.HelloDevice.TV_CONTROL_CHANGED, onWhiteboardDisplayControlUpdated);
		};
	}, [mainParticipant?.objectId, socket, roomId, whiteboardDisplayControls]);

	useEffect(() => {
		const getPatientProgram = async () => {
			const response = await getPatientRpmProgram(patientInfo?.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setRpmProgram(response.rpmProgram);
		};
		if (patientInfo) {
			getPatientProgram();
		}
	}, [patientInfo]);

	useEffect(() => {
		return conferenceConfigurations.controlsObservable.on(({ key, value }) => {
			if (key === ControlsActions.TOGGLE_HEALTH_MEASUREMENTS) {
				socketFunctions.toggleHealthData({
					isEnabled: value,
					helloDeviceId: mainParticipant.objectId,
					conferenceId: conference.conferenceId,
					participantId: localParticipant.id,
				});
			}
			if (isLiveExaminationConfigsOn() && key === ControlsActions.TOGGLE_LIVE_EXAMINATIONS && !value) {
				socketFunctions.toggleHealthData({
					isEnabled: value,
					helloDeviceId: mainParticipant.objectId,
					conferenceId: conference.conferenceId,
					participantId: localParticipant.id,
					toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
					measurementType: '',
				});
			}
		});
	}, [conference, conferenceConfigurations.controlsObservable, localParticipant.id, mainParticipant, socketFunctions]);

	useEffect(() => {
		const getEncounterId = async userId => {
			if (!userId) {
				return;
			}
			const response = await getActiveEncounter(userId);
			if (!response.error) {
				setEncounterId(response?.encounter?.id);
			}
		};

		const fetchDeviceOwner = async () => {
			if (
				!mainParticipant ||
				(mainParticipant?.role !== UserTypes.PATIENT && !(mainParticipant instanceof RemoteHelloParticipant)) ||
				mainParticipant instanceof LocalParticipant ||
				![UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole())
			) {
				return;
			}
			if (mainParticipant instanceof RemoteHelloParticipant) {
				const patientProfileRes = await getDeviceOwnerPatient(mainParticipant.objectId);
				if (patientProfileRes.error) {
					setError(patientProfileRes.error.message);
				} else {
					const { userId, profilePicture, fullName, healthcareUserId, isVirtualPatient } = patientProfileRes;
					await getEncounterId(healthcareUserId);
					const patientInfoObj = { id: userId, profilePicture, fullName, guidId: healthcareUserId, isVirtualPatient };
					if (!isVirtualPatient) {
						const patientAge = getAge(patientProfileRes?.dateOfBirth);
						setPatientInfo({ ...patientInfoObj, age: patientAge });
					} else {
						setPatientInfo(patientInfoObj);
					}
					const selectedPatient = myPatients.patients.find(item => item.userId === userId);
					dispatch(patientActionCreators.setSelectedPatient(selectedPatient ?? null));
					const response = await getExternalDevices({ patientId: userId, status: WearablesStatus.ACTIVE });
					if (response.error) {
						setError(response.error.message);
						return;
					}
					if (!response.error && response?.patientExternalIotDevices?.length > 0) {
						const found = response.patientExternalIotDevices.find(
							wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
						);
						if (found) {
							setLifeSignalsWearableId(found.iotDeviceId);
						}
					}
				}
			} else {
				setPatientInfo({ id: mainParticipant.objectId, profilePicture: mainParticipant.picture, fullName: mainParticipant.name });
				const selectedPatient = myPatients.patients.find(item => item.userId === mainParticipant.objectId);
				dispatch(patientActionCreators.setSelectedPatient(selectedPatient ?? null));
			}

			conferenceConfigurations.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, true);
		};
		fetchDeviceOwner();
		// Reason: Needs to be called only when patient changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainParticipant, isRequestUpdated]);

	useEffect(() => {
		const fetchWhiteboardControls = async () => {
			const response = await getWhiteboardControls({ roomId });
			if (response?.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			setWhiteboardDisplayControls(response);
			setIsWhiteboardAssigned(true);
		};

		const clearDisplayControlData = () => {
			setWhiteboardDisplayControls([]);
			setIsWhiteboardAssigned(false);
		};

		const checkCompanionDevice = async () => {
			if (!roomId || !getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DisplayControl)) {
				return;
			}

			setDisplayControlLoading(true);
			const response = await getCompanionDevicesByRoom(roomId);
			if (response?.error) {
				setDisplayControlLoading(false);
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}

			const whiteboardDevice = response?.result?.find(item => item.companionModeId === CompanionModeId.WHITEBOARD);
			if (whiteboardDevice) {
				await fetchWhiteboardControls();
			} else {
				clearDisplayControlData();
			}
			setDisplayControlLoading(false);
		};

		checkCompanionDevice();
	}, [roomId, roundingConfigurations]);

	const togglePeerMic = isSoundPlaying => {
		if (mainParticipant instanceof RemoteHelloParticipant) {
			const hasAudioTrack = !!tracks[Mic];
			if (isSoundPlaying && hasAudioTrack) {
				mainParticipant.remoteTrackController.toggle(Mic);
				prevPeerHasAudioRef.current = hasAudioTrack;
			} else if (!isSoundPlaying && prevPeerHasAudioRef.current) {
				mainParticipant.remoteTrackController.toggle(Mic);
			}
		}
	};

	const hideRightSidebar = () => {
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PRESCRIPTIONS, false);
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_NOTES, false);
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PHYSICAL_EXERCISES, false);
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PROCEDURES, false);
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_DIAGNOSES, false);
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PARTICIPANTS_VIEW, false);
		conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_TELEMETRY, false);
	};

	const renderHideRightSideBar = () =>
		([isDiagnosesVisible, isProceduresVisible, isNotesVisible, isPrescriptionsVisible, isCareEventsFormVisible].includes(true) ||
			(conferenceConfigurations.featureFlags.usePhysicalExercises && isPhysicalExercisesVisible)) && (
			<div onClick={hideRightSidebar} className='close-medical-info cursor-pointer'>
				<i className='material-icons-outlined'>close</i>
			</div>
		);

	const isAddCareEventsVisible = () => {
		const isCareEventsForNurses = getRoleConfigurationValue(roundingConfigurations, RoundingSettings.CareEventsForNurses);
		const isCareEventsForSafetyCompanions = getRoleConfigurationValue(
			roundingConfigurations,
			RoundingSettings.CareEventsForSafetyCompanions
		);

		return (
			getUserRole() === UserRoles.NURSE && isCareEventsFormVisible && (isCareEventsForNurses || isCareEventsForSafetyCompanions)
		);
	};

	const renderCareEvents = () =>
		isAddCareEventsVisible() && (
			<>
				{renderHideRightSideBar()}
				<AddCareEventsForm
					roundingConfigurations={roundingConfigurations}
					isRightToLeft={conferenceConfigurations.isRightToLeft}
					isDarkMode={conferenceConfigurations.isDarkMode}
					patientInfo={patientInfo}
					deviceId={mainParticipant.objectId}
					conferenceId={conference.conferenceId}
				/>
			</>
		);

	const renderMedicalInfo = () => (
		<>
			{renderHideRightSideBar()}
			{getUserRole() === UserRoles.DOCTOR && (
				<>
					{isDiagnosesVisible && (
						<AddDiagnosesOrProcedures
							visitId={conferenceConfigurations.visitId}
							type={MedicalInfoTypes.DIAGNOSES}
							setMedicalInfo={items => setDiagnoses(items)}
							diagnoses={diagnoses}
						/>
					)}
					{isProceduresVisible && (
						<AddDiagnosesOrProcedures
							visitId={conferenceConfigurations.visitId}
							type={MedicalInfoTypes.PROCEDURES}
							setMedicalInfo={items => setProcedures(items)}
							procedures={procedures}
						/>
					)}
					{isNotesVisible && (
						<AddNotes visitId={conferenceConfigurations.visitId} setMedicalInfo={item => setNotes(item)} notes={notes} />
					)}

					{isPrescriptionsVisible && (
						<PrescriptionsWrapper
							prescriptions={prescriptions}
							type={MedicalInfoTypes.PRESCRIPTIONS}
							visitId={conferenceConfigurations.visitId}
							setMedicalInfo={items => setPrescriptions(items)}
						/>
					)}
					{conferenceConfigurations.featureFlags.usePhysicalExercises && isPhysicalExercisesVisible && (
						<AddPTReport
							visitId={conferenceConfigurations.visitId}
							doctorId={localParticipant.objectId}
							patientId={patientInfo.id}
							patientName={conference.conferenceName}
							closePTReport={() => {
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PHYSICAL_EXERCISES, false);
								conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_PARTICIPANTS_VIEW, false);
							}}
						/>
					)}
				</>
			)}
		</>
	);

	const turnOnPatientHealthData = () => {
		conferenceConfigurations.onHealthDataToggleAction({
			[ControlsActions.TOGGLE_CAMERA_MEASUREMENTS]: false,
			[ControlsActions.TOGGLE_HEALTH_MEASUREMENTS]: true,
			[ControlsActions.TOGGLE_LIVE_EXAMINATIONS]: false,
			[ControlsActions.TOGGLE_PATIENT_HISTORY]: false,
		});
	};

	const toggleClassHandler = () => {
		conferenceConfigurations.onConfigurationToggleAction();
		conferenceConfigurations.onHealthDataToggleAction({
			[ControlsActions.TOGGLE_PRESCRIPTIONS]: false,
			[ControlsActions.TOGGLE_DIAGNOSES]: false,
			[ControlsActions.TOGGLE_NOTES]: false,
			[ControlsActions.TOGGLE_PROCEDURES]: false,
			[ControlsActions.TOGGLE_MEDICAL_FORMS]: false,
			[ControlsActions.TOGGLE_CONVERSATION_MODAL]: false,
			[ControlsActions.TOGGLE_CARE_EVENTS]: false,
		});
		// hasCameraControlsClass: !isLeft,
		// isOverviewOpen: isLeft,
	};

	const renderHealthMeasurements = () => {
		const isMedicalInfoActive =
			isDiagnosesVisible || isNotesVisible || isPrescriptionsVisible || isProceduresVisible || isCareEventsFormVisible;
		return (
			[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole()) &&
			patientInfo && (
				<div
					className={classNames(
						conferenceConfigurations.isHealthMeasurementsVisible ? '' : 'hidden',
						isConversationModalVisible || isMedicalInfoActive ? 'conversation-modal-visible' : ''
					)}>
					<HealthMeasurements
						patientId={patientInfo.id}
						helloDeviceId={mainParticipant.objectId}
						conferenceId={conference.conferenceId}
						participantId={localParticipant.id}
						objectType={mainParticipant.objectType}
						turnOnPatientHealthData={turnOnPatientHealthData}
						togglePeerMic={togglePeerMic}
						toggleClass={toggleClassHandler}
						isHealthMeasurementsVisible={conferenceConfigurations.isHealthMeasurementsVisible}
					/>
				</div>
			)
		);
	};

	const setMedicalInfo = (items, type) => {
		switch (type) {
			case MedicalInfoTypes.NOTES: {
				setNotes(items);
				break;
			}
			case MedicalInfoTypes.PROCEDURES: {
				setProcedures(items);
				break;
			}
			case MedicalInfoTypes.PRESCRIPTIONS: {
				setPrescriptions(items);
				break;
			}
			default: {
				setDiagnoses(items);
				break;
			}
		}
	};

	const showRpmMeasurements = () =>
		[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole()) &&
		patientInfo &&
		isLiveExaminationConfigsOn() &&
		conferenceConfigurations.isLiveExaminationOpen &&
		myPatients.hasRpmProgram;

	const renderCallWrap = () =>
		conferenceConfigurations.visitId &&
		conferenceState instanceof Conference.StateEnded && (
			<CallWrapUp
				visitId={conferenceConfigurations.visitId}
				diagnoses={diagnoses}
				procedures={procedures}
				notes={notes}
				prescriptions={prescriptions}
				cancelVisit={() => submitVisit(VisitStatus.CANCELLED)}
				submitVisit={() => submitVisit(VisitStatus.COMPLETED)}
				patientName={patientInfo?.fullName}
				patientProfilePicture={patientInfo?.profilePicture}
				setMedicalInfo={(items, type) => setMedicalInfo(items, type)}
			/>
		);

	const isMobileClient = () => {
		const patientParticipant = conferenceParticipants.find(p => p.objectId === patientInfo?.id);
		return [enums.ClientTypes.IOS, enums.ClientTypes.ANDROID].includes(patientParticipant?.clientType);
	};

	const toggleDisplayControl = async payload => {
		const response = await editWhiteboardControl({
			roomId,
			payload: {
				tvSettingType: payload.settingType,
				tvSettingValue: payload.settingValue,
				tvIndex: payload.tvIndex,
			},
		});
		if (response?.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}

		const newClonedData = _.cloneDeep(whiteboardDisplayControls);
		const itemIndex = whiteboardDisplayControls.findIndex(item => item?.settingTypeId === payload?.settingTypeId);

		if (itemIndex !== -1) {
			newClonedData[itemIndex] = { ...newClonedData[itemIndex], ...payload };
			setWhiteboardDisplayControls(newClonedData);
		}
	};

	const displayControlData = useMemo(() => {
		return whiteboardDisplayControls.find(item => item?.settingTypeId === TvSettingTypesId.DISPLAY);
	}, [whiteboardDisplayControls, roomId]);

	const isMedicalInfoActive =
		isDiagnosesVisible || isNotesVisible || isPrescriptionsVisible || isProceduresVisible || isCareEventsFormVisible;

	const renderWhiteboard = () =>
		conferenceConfigurations.isWhiteboardVisible && (
			<div className={classNames('new-experience', conferenceConfigurations.isDarkMode ? 'dark-mode' : '')}>
				<aside
					className={classNames(
						conferenceConfigurations.isWhiteboardVisible ? 'right-side right-side-monitoring conference-whiteboard-aside' : '',
						conferenceConfigurations.isWhiteboardVisible && conferenceConfigurations.isDarkMode ? 'dark-rounding-whiteboard' : ''
					)}>
					<div className='monitoring-timeline rounding-whiteboard-wrapper'>
						{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DisplayControl) && (
							<DisplayControl
								data={displayControlData}
								toggleDisplayControl={toggleDisplayControl}
								isDeviceAssigned={isWhiteboardAssigned}
								isLoading={displayControlLoading}
							/>
						)}
						{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.Whiteboard) && (
							<Whiteboard
								deviceOwnerId={patientInfo?.guidId}
								deviceId={mainParticipant?.objectId}
								patientName={patientInfo?.fullName}
								initExpanded={true}
								patientAge={patientInfo?.age}
							/>
						)}
					</div>
				</aside>
			</div>
		);

	const renderRoomSign = () =>
		conferenceConfigurations.isRoomSignOpen && (
			<div className={classNames('new-experience', conferenceConfigurations.isDarkMode ? 'dark-mode' : '')}>
				<aside
					className={classNames(
						conferenceConfigurations.isRoomSignOpen ? 'right-side right-side-monitoring conference-room-sign-aside' : '',
						conferenceConfigurations.isRoomSignOpen && conferenceConfigurations.isDarkMode ? 'dark-rounding-whiteboard' : ''
					)}>
					<div className='monitoring-timeline'>
						<RoomSign deviceOwnerId={patientInfo?.guidId} userId={patientInfo?.id} deviceId={mainParticipant?.objectId} />
					</div>
				</aside>
			</div>
		);

	return (
		<>
			{!(conferenceState instanceof Conference.StateEnded) && (
				<>
					{showRpmMeasurements() && isMobileClient() && <RpmMeasurements patientId={patientInfo.id} />}
					{renderHealthMeasurements()}
					{conferenceConfigurations.isTelemetryModalOpen && (
						<div
							className={classNames(
								conferenceConfigurations.isRightToLeft ? 'direction-rtl-wrapper' : '',
								conferenceConfigurations.isInviteParticipantsModalViewOpen ? 'hidden' : ''
							)}>
							{!iframeSrc && (
								<aside
									className={classNames(
										'right-side live-examination-kit iframe-view',
										conferenceConfigurations.isDarkMode ? 'dark-aside-frame' : ''
									)}>
									<section>
										{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.BiobeatPatch) && (
											<Button
												onClick={() => setIframeSrc('https://remote-monitoring.us.bio-beat.cloud/')}
												svgIcon={<TelemetryIcon color={LightTheme.colors.grayFive} />}
												text='Biobeat'
											/>
										)}
										{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.LifeSignalsPatch) && (
											<Button
												onClick={() =>
													setIframeSrc(`${APP_CONFIG.lifeSignalsUrl}/#/dashboard/dashboard-zoom-view/${lifeSignalsWearableId}`)
												}
												svgIcon={<TelemetryIcon color={LightTheme.colors.grayFive} />}
												text='LifeSignals'
											/>
										)}
									</section>
								</aside>
							)}
							{iframeSrc && (
								<aside className='right-side live-examination-kit iframe-view'>
									<Button
										className={classNames('back-button', conferenceConfigurations.isDarkMode ? 'back-button-dark' : '')}
										onClick={() => setIframeSrc(null)}
										icon='arrow_back'
									/>
									<iframe title='Bio beat' src={iframeSrc} />
								</aside>
							)}
						</div>
					)}
					{renderCareEvents()}
					{renderMedicalInfo()}
					{[UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole()) && patientInfo && (
						<>
							{isLiveExaminationConfigsOn() && (
								<div
									className={classNames(
										conferenceConfigurations.isLiveExaminationOpen ? '' : 'hidden',
										isConversationModalVisible || isMedicalInfoActive ? 'conversation-modal-visible' : '',
										conferenceConfigurations.isRightToLeft ? 'direction-rtl-wrapper' : ''
									)}>
									<LiveExaminationKit
										patientId={patientInfo.id}
										isVirtualPatient={patientInfo.isVirtualPatient}
										patientObjectId={getPatientDetails()?.objectId}
										conferenceId={conference.conferenceId}
										participantId={localParticipant.id}
										togglePeerMic={togglePeerMic}
										toggleClass={toggleClassHandler}
										objectType={getPatientDetails()?.objectType}
										conferenceParticipants={conferenceParticipants}
										setExaminationType={(value, measureDeviceType, measureDeviceState, doctorId) => {
											socketFunctions.toggleHealthData({
												isEnabled: !!value,
												helloDeviceId: getPatientDetails()?.objectId,
												conferenceId: conference.conferenceId,
												participantId: localParticipant.id,
												toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
												measurementType: value,
												measureDeviceType,
												measureDeviceState,
												doctorId,
												patientId: measureDeviceType === MeasureDeviceType.WATCH ? patientInfo.id : null,
											});
										}}
										selectMeasuringPoint={({ deviceType, examPoint, actioneeParticipantIds }) => {
											socketFunctions.setIotDeviceExaminationPoint({
												conferenceId: conference.conferenceId,
												participantId: localParticipant.id,
												deviceType,
												examPoint,
												actioneeParticipantIds,
											});
										}}
									/>
								</div>
							)}
							<div
								className={classNames(
									conferenceConfigurations.isRightToLeft ? 'direction-rtl-wrapper' : '',
									conferenceConfigurations.isInviteParticipantsModalViewOpen ? 'hidden' : ''
								)}>
								<aside
									className={classNames(
										conferenceConfigurations.isPatientHistoryOpen ? 'right-side live-examination-kit' : 'hidden',
										conferenceConfigurations.isPatientHistoryOpen && conferenceConfigurations.isDarkMode ? 'dark-aside-frame' : ''
									)}>
									<div
										className={classNames(
											conferenceConfigurations.isPatientHistoryOpen ? 'full-width patient-history-call-wrapper' : 'hidden',
											isConversationModalVisible || isMedicalInfoActive ? 'conversation-modal-visible' : ''
										)}>
										{patientInfo && conferenceConfigurations.isPatientHistoryOpen && (
											<PatientMain
												isCallView={true}
												isPatientHistoryVisible={conferenceConfigurations.isPatientHistoryOpen}
												patientId={patientInfo.id}
												patientGuidId={patientInfo?.guidId}
												fullName={patientInfo?.fullName}
												isDarkMode={conferenceConfigurations.isDarkMode}
												rpmProgram={rpmProgram}
											/>
										)}
									</div>
								</aside>
							</div>
						</>
					)}
					{renderWhiteboard()}
					{renderRoomSign()}
				</>
			)}
			{renderCallWrap()}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HealthDataContainer;
