import { setWhiteboardType } from 'api/configurations.js';
import { updateDepartment } from 'api/departments.js';
import { getDeviceList } from 'api/devices.js';
import { getDoctorAssigned } from 'api/doctors.js';
import { deleteOrgUnit } from 'api/healthSystems.js';
import { getRegionSubTree } from 'api/tree.js';
import { unAssignMember } from 'api/users.js';
import { getVisitingHours } from 'api/visitingHours.js';
import classNames from 'classnames';
import AssignDoctor from 'components/AssignDoctor.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import DeviceActions from 'components/DeviceActions.jsx';
import FeatureFlagsCategories from 'components/FeatureFlagsCategories.jsx';
import { Modal, Form, Dropdown, DescriptionBox, CustomTable, Button, Breadcrumb, Alert } from 'components/index.js';
import ScheduleNightVision from 'components/ScheduleNightVision.jsx';
import SectorCallSettings from 'components/SectorCallSettings.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import { CompanySettings, IntegrationTypesSettings, hospitalFeatureFlagsCategories } from 'constants/configurationEnums.js';
import {
	BoardTypes,
	ConnectivityStatus,
	DeviceListLevel,
	SectorTypes,
	TeamTypes,
	TreeHierarchyType,
	UserRoles,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AlexaSmartPropertiesWrapper from 'containers/AlexaSmartPropertiesWrapper.jsx';
import EditSectorModal from 'containers/EditSectorModal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import {
	buildTree,
	decodeHtml,
	findDeviceById,
	findSectorById,
	getAppVersion,
	getCodeNameByOsVersion,
	getConfigurationValue,
	getHealthSystemDevices,
	getHierarchyByHelloDeviceId,
	getParentSector,
	sortDevicesByTreeRoomsOrder,
} from 'infrastructure/helpers/commonHelpers.js';
import { mapSectionsToRender } from 'infrastructure/helpers/mapSectorsToRender.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { IMaskInput } from 'react-imask';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { actionCreators } from 'state/healthSystems/actions.js';

const Department = props => {
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [devices, setDevices] = useState([]);
	const [isDeleteOrgUnitModalOpen, setIsDeleteOrgUnitModalOpen] = useState(false);
	const [isDeleteDeviceModalLoading, setIsDeleteDeviceModalLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [isEditSectorModalOpen, setIsEditSectorModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [doctors, setDoctors] = useState([]);
	const [isAddDoctorShown, setIsAddDoctorShown] = useState(false);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [currentSector, setCurrentSector] = useState({});
	const [helloDeviceId, setHelloDeviceId] = useState('');
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const [isNightVisionModalOpen, setIsNightVisionModalOpen] = useState(false);
	const [isBoardTypeModalOpen, setIsBoardTypeModalOpen] = useState(false);
	const [visitingHours, setVisitingHours] = useState([]);
	const [isUnitNumberModalOpen, setIsUnitNumberModalOpen] = useState(false);
	const [unitNumberModalLoading, setUnitNumberModalLoading] = useState(false);
	const [isAlexaSmartPropertiesOpen, setIsAlexaSmartPropertiesOpen] = useState(false);
	const [isTableDataLoading, setIsTableDataLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [sectorToEdit, setSectorToEdit] = useState({ name: '', type: SectorTypes.DEPARTMENT });
	const [unitPhoneNumber, setUnitPhoneNumber] = useState('');

	const foundSector = useMemo(
		() => findSectorById(healthSystems.treeData.tree, props.match.params.departmentId),
		[healthSystems.treeData.tree, props.match.params.departmentId]
	);

	const updateCurrentDep = useCallback(foundDepartment => {
		const whiteboardType = BoardTypes.find(board => board.value === foundDepartment?.whiteboardTypeId);
		setCurrentSector(prevState => ({
			...prevState,
			...foundDepartment,
			whiteboardType,
		}));
	}, []);

	useEffect(() => {
		updateCurrentDep(foundSector);
	}, [foundSector, updateCurrentDep]);

	const fetchDevices = useCallback(async () => {
		setIsTableDataLoading(true);
		const devicesRes = await getDeviceList(DeviceListLevel.DEPARTMENT, props.match.params.departmentId);
		if (!devicesRes.error) {
			if (foundSector?.subOptions) {
				devicesRes.map(deviceRes => {
					const device = findDeviceById(foundSector.subOptions, deviceRes.solHelloDeviceId);
					if (device) {
						deviceRes.roomName = device.name;
						deviceRes.roomId = device.roomId;
					}
					return deviceRes;
				});
			}
			setDevices(devicesRes);
		}

		setIsTableDataLoading(false);
	}, [foundSector.subOptions, props.match.params.departmentId]);

	const fetchAssignedDoctors = useCallback(async () => {
		setIsTableDataLoading(true);
		const assignedDoctorsRes = await getDoctorAssigned(props.match.params.departmentId, pagination);
		if (assignedDoctorsRes.error) {
			setError(assignedDoctorsRes.error.message);
		} else {
			setDoctors(assignedDoctorsRes.doctors);
			setPagination(prevState => ({ ...prevState, totalCount: assignedDoctorsRes.totalCount }));
		}
		setIsTableDataLoading(false);
	}, [pagination, props.match.params.departmentId]);

	const fetchVisitingHours = useCallback(async () => {
		setIsTableDataLoading(true);
		const response = await getVisitingHours(DeviceListLevel.DEPARTMENT, props.match.params.departmentId);
		if (response.error) {
			setError(response.error.message);
		} else {
			const result = getInitialVisitingHoursMaped(response.visitingHours);
			const visitingHoursRearranged = reArrangeVisitingHours(result, intl);
			setVisitingHours(visitingHoursRearranged);
		}
		setIsTableDataLoading(false);
	}, [intl, props.match.params.departmentId]);

	useEffect(() => {
		fetchDevices();
	}, [fetchDevices, props.match.params.departmentId]);

	useEffect(() => {
		if (currentTab === tabsList().indexOf('doctorPooling')) {
			fetchAssignedDoctors();
		}

		if (currentTab === tabsList().indexOf('visitingHours')) {
			fetchVisitingHours();
		}

		return () => {
			setIsTableDataLoading(true);
		};
	}, [currentTab, intl, pagination.pageIndex, pagination.pageSize, props.match.params.departmentId]);

	const formatPhoneNumber = number => number && `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;

	const initDeleteOrg = useCallback(data => {
		setSectorToEdit(data);
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
	}, []);

	const deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(sectorToEdit?.typeId, sectorToEdit?.id);
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
		fetchTreeData();
		if (sectorToEdit.type === SectorTypes.DEPARTMENT) {
			history.push('/health-system');
			dispatch(actionCreators.updateBreadcrumb([]));
		}
	};

	const getConnectionType = device => (
		<>
			{[device.wifi, device.ethernet].includes(ConnectivityStatus.ON) && (
				<>
					{device.wifi === ConnectivityStatus.ON && 'WiFi'}
					{device.ethernet === ConnectivityStatus.ON && device.wifi === ConnectivityStatus.ON && ','}{' '}
					{device.ethernet === ConnectivityStatus.ON && 'Ethernet'}
				</>
			)}

			{device.wifi === ConnectivityStatus.OFF && device.ethernet === ConnectivityStatus.OFF && 'N/A'}
		</>
	);

	const getDeviceOwner = device => (
		<div className='flex'>
			{!device?.isVirtualPatientAssigned && !device?.isDefaultOwner ? decodeHtml(device.owner) : translate('unassigned')}
		</div>
	);

	const displayDevices = () => {
		if (devices.length === 0 || healthSystems.treeData.tree.length === 0) return [];
		const sortedDevicesList = sortDevicesByTreeRoomsOrder(devices, sectionChildrenData.rooms);

		return sortedDevicesList.map(device => ({
			roomName: device.roomName,
			...(isPatientDisabled() ? [] : { owner: getDeviceOwner(device) }),
			serialNumber: device.serialNumber || 'N/A',
			macAddress: device.macAddress || 'N/A',
			appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
			androidVersion: device.appVersion ? getCodeNameByOsVersion(device.osVersion) : 'N/A',
			osVersion: device.firmwareRevision || 'N/A',
			ipAddress: device.ipAddress || 'N/A',
			ssid: device.ssid || 'N/A',
			connectionType: getConnectionType(device),
			id: device.id,
			actions: (
				<DeviceActions
					setHelloDeviceId={setHelloDeviceId}
					setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
					fetchTreeData={fetchTreeData}
					isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
					helloDeviceId={helloDeviceId}
					device={device}
					isPatientDisabled={isPatientDisabled()}
					setDevices={setDevices}
					loadRoom={fetchDevices}
				/>
			),
		}));
	};

	const mapDoctors = docs =>
		docs.map(item => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
			actions: (
				<>
					{item.teamTypeId === TeamTypes.DEPARTMENT && (
						<div className='wrapped'>
							<i className='material-icons-outlined boxed-icon' id={item.id} onClick={() => setSelectedDoctor(item)}>
								delete
							</i>
						</div>
					)}
				</>
			),
		}));

	const deleteDoctor = async () => {
		const response = await unAssignMember(userSession.healthSystem.id, props.match.params.departmentId, selectedDoctor.id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setDoctors(prevState => prevState.filter(item => item.id !== selectedDoctor.id));
		setSelectedDoctor(null);
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(actionCreators.setHealthSystem(healthSystem));
			dispatch(actionCreators.setTreeData(tree));
		}
	};

	const openEditSectorModal = useCallback(
		selectedSector => {
			const sector = findSectorById(healthSystems.treeData.tree, selectedSector[`${selectedSector.type}Id`]);
			const sectorParent = getParentSector(healthSystems.treeData.tree, sector);
			sector.parentSectorName = sectorParent.name;
			sector.parentSectorType = sectorParent.type;
			setSectorToEdit(prevState => ({ ...prevState, ...sector }));
			setIsEditSectorModalOpen(prevState => !prevState);
		},
		[healthSystems.treeData.tree]
	);

	const sectionChildrenData = useMemo(() => {
		let sections = {
			floors: [],
			rooms: [],
		};
		if (healthSystems.treeData.tree.length > 0) {
			sections = mapSectionsToRender(
				healthSystems.treeData.tree,
				currentSector,
				sector => openEditSectorModal(sector),
				initDeleteOrg,
				userSession.healthSystem.treeHierarchyTypeId
			);
		}
		return sections;
	}, [
		healthSystems.treeData.tree,
		currentSector,
		initDeleteOrg,
		userSession.healthSystem.treeHierarchyTypeId,
		openEditSectorModal,
	]);

	const exportAsCsv = () => {
		return devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId }) => ({
			serialNumber,
			room: getHierarchyByHelloDeviceId(healthSystems.treeData.tree, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: getCodeNameByOsVersion(osVersion),
		}));
	};

	const hasHospitalFloors = useCallback(
		() =>
			[
				TreeHierarchyType.DEFAULT_TREE,
				TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM,
				TreeHierarchyType.HOSPITAL_FLOOR_ROOM,
			].includes(userSession.healthSystem.treeHierarchyTypeId),
		[userSession.healthSystem.treeHierarchyTypeId]
	);

	const updateUnitPhoneNumber = async () => {
		setUnitNumberModalLoading(true);
		const params = {
			name: currentSector.name,
			unitPhoneNumber,
		};

		const response = await updateDepartment({
			healthSystemId: userSession.healthSystem.id,
			hospitalId: currentSector.hospitalId,
			departmentId: currentSector.departmentId,
			params,
		});
		if (response.error) {
			setError(response.error.message);
		} else {
			setCurrentSector(prevState => ({ ...prevState, unitPhoneNumber }));
			fetchTreeData();
		}
		setIsUnitNumberModalOpen(false);
		setUnitNumberModalLoading(false);
	};

	const saveBoardType = async () => {
		const response = await setWhiteboardType({
			healthSystemId: userSession.healthSystem.id,
			hospitalId: currentSector.hospitalId,
			departmentId: currentSector.departmentId,
			whiteboardId: currentSector.whiteboardType?.value,
		});
		if (response.error) {
			setError(response.error.message);
		}
		setIsBoardTypeModalOpen(false);
		fetchTreeData();
	};

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const onBreadcrumbClick = (options, breadcrumbIndex) => {
		const breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		dispatch(actionCreators.updateBreadcrumb(breadcrumb));
	};
	const getDescriptionData = useCallback(
		() => [
			hasHospitalFloors() && {
				title: translate('floorsInThisDepartment'),
				description: sectionChildrenData.floors.length,
				id: 0,
			},
			{ title: translate('roomsInThisDepartment'), description: sectionChildrenData.rooms.length, id: 1 },
			{ title: translate('enrolledDevices'), description: devices.length, id: 2 },
			{
				title: currentSector.unitPhoneNumber ? translate('unitPhoneNumber') : '',
				description: formatPhoneNumber(currentSector.unitPhoneNumber),
				id: 3,
			},
			!!currentSector.whiteboardType && {
				title: translate('whiteboardType'),
				description: currentSector.whiteboardType?.label,
				id: 4,
			},
		],
		[currentSector, devices, hasHospitalFloors, sectionChildrenData]
	);

	const tabsList = () =>
		[
			'devices',
			'rooms',
			hasHospitalFloors() && 'floors',
			'doctorPooling',
			'visitingHours',
			getUserRole() !== UserRoles.SUPER_USER && 'featureFlags',
			'defaultCamera',
		].filter(Boolean);

	return (
		<div className='organization'>
			<div className='flex sector-header-wrapper'>
				<div className='breadcrumb-container hs-breadcrumb-container'>
					<Breadcrumb links={healthSystems.breadcrumb} onBreadcrumbClick={onBreadcrumbClick} isFromHealthSystems={true} />
				</div>
				<div className='sector-header flex'>
					<DescriptionBox data={getDescriptionData()} />
					<div className='sector-btn-actions'>
						<Button
							text={translate('deleteDepartment')}
							imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
							variant='red'
							onClick={() =>
								initDeleteOrg({ ...currentSector, id: props.match.params.departmentId, typeId: DeviceListLevel.DEPARTMENT })
							}
							disabled={!currentSector.departmentId}
						/>
						<Button
							text={translate('editDetails')}
							onClick={() => openEditSectorModal(currentSector)}
							imgIcon={`${healthCareCdnUrl}admin/edit.svg`}
							variant='yellow'
							disabled={!currentSector.departmentId}
						/>
						<Dropdown
							position='bottom'
							icon='expand_more'
							className={classNames('organization-dropdown', { disabled: !currentSector.departmentId })}>
							<Button
								text={translate('addDoctor')}
								imgIcon={`${healthCareCdnUrl}admin/add-doctor.svg`}
								onClick={() => setIsAddDoctorShown(true)}
							/>
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
								onClick={() => setIsVisitingHoursModalOpen(true)}
								text={intl.formatMessage({ id: 'visitingHours' })}
							/>
							{devices.length > 0 && (
								<>
									<Button
										variant='white'
										imgIcon={`${healthCareCdnUrl}admin/export-as-csv.svg`}
										text={
											<CSVLink
												data-cy='exportAsCSVBtn'
												data={exportAsCsv()}
												filename={`${currentSector.name}-${(+new Date()).toString()}.csv`}>
												{translate('exportAsCSV')}
											</CSVLink>
										}
									/>
									{getConfigurationValue(companyConfigurations[IntegrationTypesSettings.ALEXA]) && (
										<Button
											variant='white blue-icon'
											text={intl.formatMessage({ id: 'alexaSmartProperties' })}
											size='medium'
											icon='home_max'
											onClick={() => setIsAlexaSmartPropertiesOpen(prev => !prev)}
										/>
									)}
								</>
							)}
							<Button
								variant='white blue-icon'
								text={intl.formatMessage({ id: 'unitPhoneNumber' })}
								size='medium'
								icon='contact_emergency'
								onClick={() => setIsUnitNumberModalOpen(true)}
							/>
							<Button
								text={translate('boardType')}
								variant='white'
								onClick={() => setIsBoardTypeModalOpen(true)}
								icon='dashboard'
							/>
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/night-vision.svg`}
								onClick={() => setIsNightVisionModalOpen(true)}
								text={intl.formatMessage({ id: 'scheduleNightVision' })}
							/>
						</Dropdown>
					</div>
				</div>
			</div>
			<Tabs
				activeIndex={currentTab}
				onChange={index => {
					setPagination({ pageSize: 10, pageIndex: 0, totalCount: 0 });
					setCurrentTab(index);
				}}>
				<TabList>
					{tabsList().map(item => (
						<Tab key={item}>{translate(item)}</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('room'), id: 'roomName' },
								...(isPatientDisabled() ? [] : [{ title: translate('patient'), id: 'owner' }]),
								{ title: translate('deviceSerialNumber'), id: 'serialNumber' },
								{ title: translate('macAddress'), id: 'macAddress' },
								{ title: translate('appVersion'), id: 'appVersion' },
								{ title: translate('androidVersion'), id: 'androidVersion' },
								{ title: translate('osVersion'), id: 'osVersion' },
								{ title: translate('ipAddress'), id: 'ipAddress' },
								{ title: 'SSID', id: 'ssid' },
								{ title: translate('connectionType'), id: 'connectionType' },
								{ title: translate('actions'), id: 'actions', columnClass: 'normal-wrap' },
							]}
							rows={isTableDataLoading ? [] : displayDevices()}
							className='admin-table'
							isLoading={isTableDataLoading}
						/>
					</TabPanel>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('roomNames'), id: 'name' },
								{ title: '', id: 'edit' },
							]}
							rows={sectionChildrenData.rooms}
							isEditable={false}
						/>
					</TabPanel>
					{hasHospitalFloors() && (
						<TabPanel>
							<CustomTable
								headers={[
									{ title: translate('floorNames'), id: 'name' },
									{ title: '', id: 'edit' },
								]}
								rows={sectionChildrenData.floors}
								isEditable={false}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('doctorPooling'), id: 'name' },
								{ title: '', id: 'actions' },
							]}
							rows={isTableDataLoading ? [] : mapDoctors(doctors)}
							isEditable={false}
							isLoading={isTableDataLoading}
						/>
						<Pagination
							totalCount={pagination.totalCount}
							pageSize={pagination.pageSize}
							pageIndex={pagination.pageIndex}
							onChange={(pageSize, pageIndex) => setPagination(prevState => ({ ...prevState, pageSize, pageIndex }))}
						/>
					</TabPanel>
					<TabPanel>
						<CustomTable
							headers={[
								{ title: translate('monday'), id: 0 },
								{ title: translate('tuesday'), id: 1 },
								{ title: translate('wednesday'), id: 2 },
								{ title: translate('thursday'), id: 3 },
								{ title: translate('friday'), id: 4 },
								{ title: translate('saturday'), id: 5 },
								{ title: translate('sunday'), id: 6 },
							]}
							rows={isTableDataLoading ? [] : visitingHours}
							className='admin-table'
							isEditable={false}
							isLoading={isTableDataLoading}
						/>
					</TabPanel>
					{getUserRole() !== UserRoles.SUPER_USER && (
						<TabPanel>
							<FeatureFlagsCategories
								selectedHealthSystem={{ value: userSession.healthSystem.id }}
								levelId={props.match.params.departmentId}
								currentSectorType={DeviceListLevel.DEPARTMENT}
								featureFlagsCategories={hospitalFeatureFlagsCategories}
								setError={setError}
							/>
						</TabPanel>
					)}
					<TabPanel>
						<SectorCallSettings
							selectedHealthSystem={userSession?.healthSystem?.id}
							levelId={DeviceListLevel.DEPARTMENT}
							sectorId={props.match.params.departmentId}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
			<Modal
				modalSelector='deleteDepartmentModal'
				display={isDeleteOrgUnitModalOpen}
				position='center'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={deleteOrgUnitSubmit}
				onModalClose={() => setIsDeleteOrgUnitModalOpen(prevState => !prevState)}>
				<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
					<p className='paragraph-break-word'>
						{translate('sureToDeleteOrg', {
							value: `${intl.formatMessage({ id: sectorToEdit.type })} ${sectorToEdit.name}`,
						})}
					</p>
				</Form>
			</Modal>
			<Modal
				display={isBoardTypeModalOpen}
				position='right'
				className='border-radius-modal-wrapper board-type-modal'
				onModalSubmit={saveBoardType}
				onModalClose={() => setIsBoardTypeModalOpen(false)}>
				<Form
					title={translate('boardType')}
					onSubmit={event => event.preventDefault()}
					description={intl.formatMessage({ id: 'boardTypeModalDesc' })}>
					<div style={{ height: '180px' }}>
						<AsyncSelect
							name='boardType'
							value={currentSector.whiteboardType}
							placeholder={translate('selectBoard')}
							defaultOptions={BoardTypes}
							onChange={val => setCurrentSector(prevState => ({ ...prevState, whiteboardType: val }))}
						/>
					</div>
				</Form>
			</Modal>
			{isEditSectorModalOpen && (
				<EditSectorModal
					onEditSector={fetchTreeData}
					onModalClose={() => setIsEditSectorModalOpen(false)}
					isEditSectorModalOpen={true}
					sectorData={sectorToEdit}
				/>
			)}
			{isAddDoctorShown && (
				<AssignDoctor
					onSubmit={() => {
						fetchAssignedDoctors();
						setIsAddDoctorShown(false);
					}}
					onModalClose={() => setIsAddDoctorShown(false)}
					levelId={props.match.params.departmentId}
					doctors={doctors}
				/>
			)}
			{selectedDoctor && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel={translate('delete')}
					onModalSubmit={deleteDoctor}
					onModalClose={() => setSelectedDoctor(null)}>
					<Form title={translate('deleteDoctor')} onSubmit={event => event.preventDefault()}>
						<p>
							{translate('sureToDeleteDoctor', {
								value: `${selectedDoctor.firstName} ${selectedDoctor.lastName}`,
							})}
						</p>
					</Form>
				</Modal>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration
					setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen}
					sector={currentSector}
					onSubmit={fetchVisitingHours}
				/>
			)}
			{isNightVisionModalOpen && (
				<ScheduleNightVision
					onModalClose={() => setIsNightVisionModalOpen(false)}
					sector={currentSector}
					fetchTreeData={fetchTreeData}
				/>
			)}
			{isAlexaSmartPropertiesOpen && (
				<AlexaSmartPropertiesWrapper
					sector={currentSector}
					setIsAlexaSmartPropertiesOpen={setIsAlexaSmartPropertiesOpen}
					isAlexaSmartPropertiesOpen={isAlexaSmartPropertiesOpen}
				/>
			)}
			{isUnitNumberModalOpen && (
				<Modal
					display={true}
					position='right'
					primaryButtonLabel={translate(currentSector.unitPhoneNumber ? 'update' : 'add')}
					onModalSubmit={updateUnitPhoneNumber}
					onModalClose={() => setIsUnitNumberModalOpen(false)}
					className='unit-nr-modal'
					primaryButtonLoading={unitNumberModalLoading}>
					<Form
						className='mi-input-wrapper'
						title={translate('addUnitPhoneNumber')}
						onSubmit={event => event.preventDefault()}
						description={intl.formatMessage({ id: 'unitPNModalDesc' })}>
						<IMaskInput
							mask='+1 (000) 000-0000'
							unmask={true}
							lazy={false}
							placeholder={intl.formatMessage({ id: 'unitPhoneNumber' })}
							defaultValue={formatPhoneNumber(currentSector.unitPhoneNumber)}
							onAccept={value => setUnitPhoneNumber(value)}
							className='number-input-wo-arrows bottom-s'
						/>
					</Form>
				</Modal>
			)}
		</div>
	);
};

export default Department;
