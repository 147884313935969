import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import FormInput from 'components/FormInput.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { DeviceListLevel } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import { configurationTypeIds } from 'constants/integrationEnums.js';

const EcareManagerAddEditBaseUrl = ({ itemToEdit, fieldErrors, setConfigParams }) => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		itemToEdit?.healthSystemId
			? {
					value: itemToEdit.healthSystemId,
					label: allHealthSystems.find(item => item.id === itemToEdit.healthSystemId)?.name,
			  }
			: { value: '0', label: 'All' }
	);
	const [selectedHospital, setSelectedHospital] = useState({ value: '0', label: 'All' });
	const [hospitals, setHospitals] = useState([]);
	const [baseUrl, setBaseUrl] = useState(itemToEdit?.baseUrl ? itemToEdit.baseUrl : '');
	const [isLoading, setIsLoading] = useState(false);
	const [skipSslVerification, setSkipSslVerification] = useState(!!itemToEdit?.skipSslVerification);
	const [error, setError] = useState('');

	useEffect(() => {
		const fetchHospitals = async () => {
			if (!selectedHealthSystem) {
				return;
			}
			if (selectedHealthSystem.value !== '0') {
				setIsLoading(true);
			}
			let hsHospitals = [];
			if (selectedHealthSystem.value !== '0' && allHealthSystems.find(item => item.id === selectedHealthSystem.value)) {
				const res = await getHealthSystemHospitals(selectedHealthSystem.value);
				if (res.error) {
					setError(res.error.message);
				} else {
					hsHospitals = res;
				}
			}
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			hospitalArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
			const foundHospital = hospitalArray.find(item => item.value === itemToEdit?.hospitalId);
			setHospitals(hospitalArray);
			setSelectedHospital(foundHospital || hospitalArray[0]);
			setIsLoading(false);
		};
		fetchHospitals();
	}, [selectedHealthSystem]);

	const onHealthSystemChange = async event => {
		setSelectedHealthSystem(event);
	};

	useEffect(() => {
		setConfigParams({
			configurationTypeId: configurationTypeIds.ECARE_BASE_URL,
			nodeId: getNodeId().value !== '0' ? getNodeId().value : null,
			parentNodeId: selectedHospital.value !== '0' ? selectedHealthSystem.value : null,
			nodeName: getNodeId().label,
			nodeLevel: getNodeLevel(),
			configJson: JSON.stringify({ baseUrl, skipSslVerification }),
		});
	}, [selectedHealthSystem, selectedHospital, baseUrl, setConfigParams, skipSslVerification]);

	const getNodeId = () => (selectedHospital.value !== '0' ? selectedHospital : selectedHealthSystem);

	const getNodeLevel = () => {
		if (selectedHospital.value !== '0') {
			return DeviceListLevel.HOSPITAL;
		}
		if (selectedHealthSystem.value !== '0') {
			return DeviceListLevel.HEALTH_SYSTEM;
		}
		return null;
	};

	const onFormHospitalSelect = event => {
		setSelectedHospital(event);
	};

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={onHealthSystemChange}
				/>
			</div>

			<div className='input'>
				<p className='label'>{translate('selectHospital')}</p>
				<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
				<Select
					isDisabled={hospitals.length === 1 || isLoading}
					value={selectedHospital}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={hospitals}
					onChange={event => onFormHospitalSelect(event)}
				/>
			</div>
			<div className='input'>
				<FormInput
					text={`${intl.formatMessage({ id: 'baseURL' })}*`}
					id='baseUrl'
					placeholder={intl.formatMessage({ id: 'baseURL' })}
					type='text'
					value={baseUrl}
					onChange={event => setBaseUrl(event.target.value)}
					maxLength={256}
					onKeyDown={doNotAllowSpaceAsFirstCharacter}
					error={fieldErrors?.baseUrl}
					touched={fieldErrors?.baseUrl}
				/>
			</div>
			<div className='flex flex-align-center'>
				<p className='font-18'>{translate('skipSslVerification')}</p>
				<div
					className='rounded-slider-switch'
					style={{ marginLeft: 'var(--spacing-m)' }}
					onClick={() => setSkipSslVerification(prevState => !prevState)}>
					<input type='checkbox' checked={skipSslVerification} onChange={() => null} />
					<span className='rounded-slider' />
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default EcareManagerAddEditBaseUrl;
