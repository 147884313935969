import { deleteConfiguration, getConfigsByGroup } from 'api/eManager.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Modal from 'components/Modal.jsx';
import { DeviceListLevel } from 'constants/enums.js';
import { configurationTypeIds, groupTypes } from 'constants/integrationEnums.js';
import EpicAddEdit from 'containers/Configurations/EpicAddEdit.jsx';
import translate from 'i18n-translations/translate.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import { getLevelDescriptionById, getPreferredLanguageLocale, isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { convertUTCDateToLocalDate } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const EcareManager = ({ group = { id: 0, title: '' } }) => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const [error, setError] = useState('');
	const [apiKeyList, setApiKeyList] = useState([]);
	const [itemToEdit, setItemToEdit] = useState(null);
	const [configToDelete, setConfigToDelete] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetch, setIsRefetch] = useState(null);
	const [isAddOpen, setIsAddOpen] = useState(false);
	const [hasApiConfig, setHasApiConfig] = useState(false);

	const apiKeyHeaders = [
		{ title: intl.formatMessage({ id: 'vendor' }), id: 0 },
		{ title: intl.formatMessage({ id: 'scope' }), id: 1 },
		{ title: intl.formatMessage({ id: 'location' }), id: 2 },
		groupTypes.TRANSLATION_SERVICES.id !== group.id && {
			title: intl.formatMessage({ id: 'nodeId' }),
			id: 3,
		},
		{ title: intl.formatMessage({ id: 'createdBy' }), id: 4 },
		{ title: intl.formatMessage({ id: 'dateCreated' }), id: 5 },
		[groupTypes.ECM.id, groupTypes.SMART_FHIR.id].includes(group.id) && {
			title: intl.formatMessage({ id: 'key' }),
			id: 6,
		},
		{ title: '', id: 7 },
	].filter(Boolean);

	const getApiKeyFromConfig = (obj, property) => {
		const parsed = JSON.parse(obj);
		const result = parsed ? parsed[property] : null;
		return result;
	};

	const getHealthSystemId = item => {
		if (item.nodeLevel === DeviceListLevel.HOSPITAL) {
			return item.parentNodeId;
		}
		if (item.nodeLevel === DeviceListLevel.HEALTH_SYSTEM) {
			return item.nodeId;
		}
		return null;
	};

	const handleOnClick = (item, properties) => {
		const itemToSet = {
			healthSystemId: getHealthSystemId(item),
			hospitalId: item.nodeLevel === DeviceListLevel.HOSPITAL ? item.nodeId : null,
			id: item.id,
			userId: item.userId,
			configurationTypeId: item.configurationTypeId,
		};
		const parsed = isJSON(item.configJson) ? JSON.parse(properties) : {};
		Object.keys(parsed).forEach(key => {
			if (key) {
				itemToSet[key] = getApiKeyFromConfig(item.configJson, key);
			}
		});
		setItemToEdit(itemToSet);
		setIsAddOpen(true);
	};

	const getCreatedBy = item => (isJSON(item.configJson) ? JSON.parse(item.configJson).createdBy : 'N/A');

	const getDateCreated = item => {
		const createdAt = new Date(item.createdDate);
		const convertedDate = convertUTCDateToLocalDate(createdAt);
		return moment(convertedDate).locale(getPreferredLanguageLocale()).format('MMM DD, YYYY h:mm A');
	};

	const transformItem = (item, property) => {
		return {
			vendor: getConfigTypeDescription(item.configurationTypeId),
			scope: intl.formatMessage({ id: getLevelDescriptionById(item.nodeLevel) }),
			location: item.nodeName || 'All',
			...(group.id !== groupTypes.TRANSLATION_SERVICES.id && { nodeId: item.nodeId || item.tenantId }),
			createdBy: getCreatedBy(item),
			dateCreated: getDateCreated(item),
			...([groupTypes.ECM.id, groupTypes.SMART_FHIR.id].includes(group.id) && {
				key: getApiKeyFromConfig(item.configJson, property),
			}),
			delete: (
				<div className='wrapped'>
					<TableEditButton onClick={() => handleOnClick(item, item.configJson)} />
					<TableDeleteButton onClick={() => setConfigToDelete(item)} />
				</div>
			),
		};
	};

	const getConfigTypeDescription = type => {
		let result;
		switch (type) {
			case configurationTypeIds.ECARE_API_KEY: {
				result = intl.formatMessage({ id: 'eCareApiKey' });
				break;
			}
			case configurationTypeIds.ECARE_BASE_URL: {
				result = intl.formatMessage({ id: 'eCareBaseUrl' });
				break;
			}
			case configurationTypeIds.HILLROM: {
				result = 'Hill Room';
				break;
			}
			case configurationTypeIds.EPIC: {
				result = 'Epic';
				break;
			}
			case configurationTypeIds.CERNER: {
				result = 'Cerner';
				break;
			}
			case configurationTypeIds.MOVIES: {
				result = intl.formatMessage({ id: 'movies' });
				break;
			}
			case configurationTypeIds.LIVE_TV_CHANNELS: {
				result = intl.formatMessage({ id: 'liveTvChannels' });
				break;
			}
			case configurationTypeIds.MUSIC: {
				result = intl.formatMessage({ id: 'music' });
				break;
			}
			case configurationTypeIds.GLOBO: {
				result = 'Globo';
				break;
			}
			case configurationTypeIds.AMN: {
				result = 'AMN';
				break;
			}
			case configurationTypeIds.LANGUAGE_LINE: {
				result = 'Language Line';
				break;
			}
			default: {
				result = '';
			}
		}
		return result;
	};

	useEffect(() => {
		const fetchConfigs = async () => {
			const configsRes = await getConfigsByGroup(group.id);
			if (configsRes.error) {
				setError(configsRes.error.message);
			} else {
				const items = configsRes.result.map(item => transformItem(item, getProperty(item.configurationTypeId)));
				setApiKeyList(items);
				setHasApiConfig(configsRes.result.some(item => item.configurationTypeId === configurationTypeIds.ECARE_API_KEY));
			}
		};
		fetchConfigs();
	}, [isRefetch, group.id]);

	const getProperty = type => {
		let result;
		switch (type) {
			case configurationTypeIds.ECARE_BASE_URL:
			case configurationTypeIds.GLOBO:
			case configurationTypeIds.AMN:
			case configurationTypeIds.LANGUAGE_LINE: {
				result = 'baseUrl';
				break;
			}
			case configurationTypeIds.ECARE_API_KEY: {
				result = 'apiKey';
				break;
			}
			case configurationTypeIds.HILLROM: {
				result = 'secretKey';
				break;
			}
			case configurationTypeIds.EPIC:
			case configurationTypeIds.CERNER: {
				result = 'clientId';
				break;
			}
			case configurationTypeIds.MOVIES:
			case configurationTypeIds.LIVE_TV_CHANNELS:
			case configurationTypeIds.MUSIC: {
				result = 'type';
				break;
			}
			default: {
				result = '';
			}
		}
		return result;
	};

	const onDeleteConfig = async () => {
		setIsLoading(true);
		const response = await deleteConfiguration(configToDelete.id);
		if (response.error) {
			setError(response.error.message);
		}
		setConfigToDelete(null);
		setIsRefetch({ id: configToDelete.id, isDelete: true });
		setIsLoading(false);
	};

	return (
		<>
			<div>
				<CustomTable
					headers={apiKeyHeaders}
					rows={apiKeyList.map(item => ({
						...item,
						type: getConfigTypeDescription(item.type),
						createdBy: item.createdBy || 'N/A',
						dateCreated: item.dateCreated || 'N/A',
					}))}
					headerClass='configs-table-headers'>
					<h3>{intl.formatMessage({ id: group.title }, { value: companySettings.nurseDisplayName })}</h3>
					<Button
						text={intl.formatMessage({ id: 'addConfiguration' })}
						onClick={() => {
							setIsAddOpen(true);
							setItemToEdit(null);
						}}
					/>
				</CustomTable>
				<Modal
					modalSelector='ecareModal'
					display={configToDelete}
					position='center'
					isLoading={isLoading}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					onModalSubmit={onDeleteConfig}
					onModalClose={() => setConfigToDelete(null)}
					shouldSubmitOnEnter={false}
					primaryButtonLabel={translate('yes')}>
					<form>
						<h3>{translate('deleteConfiguration')}</h3>
						<p>{translate('areYouSureToDeleteConfig')}</p>
					</form>
				</Modal>
				{isAddOpen && (
					<EpicAddEdit
						hasApiConfig={hasApiConfig}
						itemToEdit={itemToEdit}
						setIsAddOpen={setIsAddOpen}
						setIsRefetch={setIsRefetch}
						groupId={group.id}
					/>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default EcareManager;
